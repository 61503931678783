import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FacetDialogName } from 'Components/facetPanel/common/index.ts';
import DialogButton from 'Components/smallComponents/dialogButton.js';
import {
  createFilteredResponseSelectionSelector,
  createShouldShowFacetPanelSelector,
} from 'Core/selectors/search.js';
import widgetsConfig from 'Models/uiConfig/componentlessWidgetsConfig.ts';
import { cloneSafe } from 'Utils/components.ts';

// TODO: load widgets from props
const facetDialogWidget = widgetsConfig.find((w) => w.type === 'FacetDialog');
export default function FacetDialogButton({ template }) {
  const { fields, ignoreFields } = facetDialogWidget || {};
  const showButton = useShowButton(fields, ignoreFields);
  const withSelection = useWithSelection(fields, ignoreFields);
  const selectedFacetsCount = useSelector(
    (state) => state.search.response.facets.filter((facet) => facet.selection.length).length,
  );

  const dialogButton = (templ) => {
    if (!showButton) {
      return null;
    }
    const props = {
      template: templ,
      dialogName: facetDialogWidget ? FacetDialogName : 'facet-dialog',
      templateParams: { withSelection, selectedFacetsCount },
      key: 'button',
    };
    return <DialogButton {...props} />;
  };

  const appendedClasses = withSelection && 'with-selection';

  const component = template.call({ dialogButton });

  return cloneSafe(component, null, { appendedClasses });
}

function useShowButton(fields, ignoreFields) {
  return useSelector(
    useMemo(() => createShouldShowFacetPanelSelector(fields, ignoreFields), [fields, ignoreFields]),
  );
}

function useWithSelection(fields, ignoreFields) {
  const filteredSelector = useMemo(
    () => createFilteredResponseSelectionSelector(fields, ignoreFields),
    [fields, ignoreFields],
  );
  return useSelector((state) => !!filteredSelector(state).length);
}

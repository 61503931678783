import { isolatedDialog, isolatedHeader } from 'Addons/fitmentSearch/isolatedKeys.ts';
import { vehicleLandingFacetField } from 'Addons/fitmentSearch/vehicleLandingFacetField.ts';
import { dialogOpened } from 'Core/actions/dialog.ts';
import {
  clearVehicleDialogRequest,
  clearVerifyFitmentRequest,
  setFitmentSearchResponseFacets,
  setVehicleDialogSelection,
  vehicleSelected,
  sendInitFitmentSearchRequest,
  garageCleanupRequested,
  vehicleDiscardingRequested,
  setGarageRequested,
  removalFromGarageRequested,
  replaceVehicleValue,
  sendVehicleDialogRequest,
  sendVerifyFitmentRequest,
  setVerifyFitmentProductId,
  setVerifyFitmentFacets,
  setVerifyFitmentProduct,
  replaceVerifyFitmentVehicleValue,
  forceVehicleSelection,
} from 'Core/actions/fitmentSearch/index.js';
import { replaceInHistory, replaceLocation } from 'Core/actions/redirect.ts';
import { setResponse } from 'Core/actions/response.ts';
import { epicFromHandlers } from 'Core/epics/common.js';
import { tryResponseRedirect } from 'Core/epics/redirect.js';
import {
  createFitmentSearchRequestSelectionSelector,
  selectedVehicleSelector,
  createFitmentSearchSelectionChangedSelector as createSelectionChangedSelector,
  vehicleInexactSelector,
  createAllRequiredFieldsHaveSelectionSelector,
  createRequiredFitmentSearchFieldsSelector,
  createFitmentSearchResponseFacetsSelector,
  createResponseVehicleSelector,
  createIsServerChangedVehicleSelector,
  createFitmentSearchExtraFieldsSelector,
  isSpecifyDialogOpenedSelector,
  isVehicleSelectionForcedSelector,
  garageDataSelector,
  verifyFitmentProductIdSelector,
  verifyFitmentRequestSelectionSelector,
  fitmentSearchFieldsSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { createFilteredResponseSelectionSelector } from 'Core/selectors/search.js';
import { FacetCollection, Vehicle } from 'Models';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import productConfig from 'Models/uiConfig/productConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { getUriFromRequest } from 'Modules/converter/index.js';
import { abortControllerCreator, search, saveGarageToAccount } from 'Modules/serverApi/index.js';

const createAbortController = abortControllerCreator();

export default epicFromHandlers({
  [garageCleanupRequested]: (arg) => {
    sendRequestsIfNeeded(arg);
    onGarageUpdated(arg);
  },
  [setGarageRequested]: (arg) => {
    const {
      dispatch,
      action: {
        payload: { vehicles, selectVehicle },
      },
    } = arg;

    if (selectVehicle) {
      dispatch(vehicleSelected(vehicles.last, { isVehicleAutoSelected: true }));
    }

    onGarageUpdated(arg);
  },
  [removalFromGarageRequested]: (arg) => {
    if (arg.action.meta.wasSelected) {
      sendRequestsIfNeeded(arg);
    }

    onGarageUpdated(arg);
  },
  [dialogOpened]: (arg) => {
    const {
      state,
      dispatch,
      action: {
        payload,
        meta: { vehicleWidgetIncluded, withCurrentVehicle },
      },
    } = arg;
    if ((payload !== 'VehicleWidgetDialog' && !vehicleWidgetIncluded) || vehicleInexactSelector(state)) {
      return;
    }
    if (withCurrentVehicle) {
      const { selection } = selectedVehicleSelector(state);
      dispatch(setVehicleDialogSelection(selection));
    } else {
      sendIsolatedRequest(arg, isolatedDialog);
    }
  },
  [clearVehicleDialogRequest]: (arg) => sendIsolatedRequest(arg, isolatedDialog),
  [sendVehicleDialogRequest]: (arg) => sendIsolatedRequest(arg, isolatedDialog),
  [setVehicleDialogSelection]: (arg) => sendIsolatedRequest(arg, isolatedDialog),
  [clearVerifyFitmentRequest]: sendVerifyFitmentRequestFunc,
  [sendVerifyFitmentRequest]: sendVerifyFitmentRequestFunc,
  [replaceVerifyFitmentVehicleValue]: sendVerifyFitmentRequestFunc,
  [sendInitFitmentSearchRequest]: (arg) => sendIsolatedRequest(arg, isolatedHeader, true),
  [setFitmentSearchResponseFacets]: tryAutoSelectVehicle,
  [setResponse]: (arg) => {
    syncUrlWithVehicle(arg);
    return tryAutoSelectVehicle(arg); // do return because of the test
  },
  [replaceVehicleValue]: (arg) => {
    const {
      state,
      action: {
        meta: { isolatedKey },
      },
    } = arg;
    if (isolatedKey && createSelectionChangedSelector(isolatedKey)(state)) {
      sendIsolatedRequest(arg, isolatedKey);
    }
  },
  [vehicleDiscardingRequested]: (arg) => {
    const { isInDialog, globalVehicleDiscardEnabled } = arg.action.meta;

    if (isInDialog) {
      sendIsolatedRequest(arg, isolatedDialog);
    }

    if (!isInDialog || globalVehicleDiscardEnabled) {
      sendRequestsIfNeeded(arg);
    }

    onGarageUpdated(arg);
  },
  [vehicleSelected]: (arg) => {
    if (arg.action.meta.sendIsolatedRequest) {
      sendRequestsIfNeeded(arg);
    }

    onGarageUpdated(arg);
  },
  [forceVehicleSelection]: (arg) => sendIsolatedRequest(arg, isolatedDialog),
  DISCARD: ({ action: { value: { field } = {} } }) => {
    if (field === vehicleLandingFacetField) {
      return vehicleDiscardingRequested({ mayDiscardValue: false });
    }
  },
  RESET_REQUEST: ({ state }) => {
    const selection = createFilteredResponseSelectionSelector()(state);
    const isVehicleLandingFacetWasSelected = selection.some((s) => s.field === vehicleLandingFacetField);

    if (isVehicleLandingFacetWasSelected) {
      return vehicleDiscardingRequested({ mayDiscardValue: false });
    }
  },
});

function sendRequestsIfNeeded(arg) {
  const {
    action: {
      meta: {
        isSelectedFromGarage,
        isSelectedFromVerifyFitment,
        isolatedKey,
        sendIsolatedRequest: sendIsolatedRequestProp,
      } = {},
    },
  } = arg;

  if (
    (fitmentSearchConfig.needInitFitmentSearchRequest && !isSelectedFromVerifyFitment) ||
    isSelectedFromGarage
  ) {
    sendIsolatedRequest(arg, isolatedHeader);
  }

  if (sendIsolatedRequestProp && isolatedKey === isolatedDialog) {
    sendIsolatedRequest(arg, isolatedDialog);
  }

  if (fitmentSearchConfig.needInitVerifyFitmentRequest) {
    sendVerifyFitmentRequestFunc(arg);
  }
}

function sendIsolatedRequest(arg, isolatedKey, onInit) {
  const {
    state,
    dispatch,
    action: {
      meta: { extra = {}, responseMeta = {}, sendIsolatedRequest, vehicle } = {},
      type: actionType,
      payload,
    },
  } = arg;

  const preselection =
    fitmentSearchConfig.isOnCategorySelectionPage && isolatedKey === isolatedHeader
      ? requestConfig.localPreselection
      : [];

  // TODO: better to add a reaction to the vehucleSelected in isolatedHeader.request.selection,
  // but because of our createReducersFromShorthands function, epics come before the state change
  const actionVehicle = actionType === vehicleSelected.type ? payload : vehicle;
  const fitmentSearchSelection = actionVehicle
    ? actionVehicle.selection
    : createFitmentSearchRequestSelectionSelector(isolatedKey)(state);

  const ignorePageContext = isolatedKey === isolatedDialog;

  const request = {
    selection: [...preselection, ...fitmentSearchSelection],
    pageSize: '0',
    mode: 'YMM',
    extra: {
      ...extra,
      ...(ignorePageContext && { ignorePageContext }),
    },
  };

  search(request, createAbortController()).then((response) => {
    if (response.State === 'cancelled' || tryResponseRedirect(arg, response)) {
      return;
    }

    dispatch(
      setResponse(response, {
        isolatedKey,
        onInit,
        sendIsolatedRequest,
        ...responseMeta,
      }),
    );
  });
}

function sendVerifyFitmentRequestFunc(arg) {
  const {
    state,
    dispatch,
    action: {
      meta: {
        extra: { vehicleJustDiscarded, vehicleJustSelected } = {},
        isAutoVehicleSelectionDisabled,
        onInit,
        productId: customProductId,
        vehicle,
      } = {},
    },
  } = arg;

  const productId = customProductId ?? verifyFitmentProductIdSelector(state);

  if (customProductId) {
    setVerifyFitmentProductId(customProductId);
  }

  if (
    !productId ||
    (arg.action.type === vehicleSelected.type && !arg.action.meta?.doNotRedirectOnVehicleSelect)
  ) {
    return;
  }

  const selection = vehicle ? vehicle.selection : verifyFitmentRequestSelectionSelector(state);

  const request = {
    selection,
    pageSize: 1,
    filterQuery: `${productConfig.fields.id}:${productId}`,
    mode: 'VerifyFitment',
    variantsMode: 'Strip',
  };

  search(request, createAbortController()).then((response) => {
    if (response.State === 'cancelled') {
      return;
    }

    if (onInit) {
      fitmentSearchConfig.onVerifyFitmentInitResponseReceived?.(response);
    } else {
      fitmentSearchConfig.onVerifyFitmentResponseReceived?.(response);
    }

    const { Actions: [{ Type, RedirectUrl } = {}] = [] } = response;
    if (Type === 'Redirect' && RedirectUrl && window.location.pathname !== RedirectUrl) {
      // Handle the VerifyFitment redirects here instead of with 'tryResponseRedirect' function.

      // 1. Ensure a vehicle is selected before redirecting.
      // If the new vehicle isn't selected, the previous vehicle will remain selected on the next page in the VerifyFitment widget.
      // This happens because this is the server redirect, and the new vehicle isn't included in the URL.
      // We use 'setTimeout' to delay the redirect, allowing time for the new vehicle to be selected first.

      // 2. The 'allowRedirect' flag in the 'vehicleSelected' action is set to 'false' for the VerifyFitment
      // to prevent any unwanted redirects.

      setTimeout(dispatch(replaceLocation(RedirectUrl)), 0);
    }

    const fitmentFields = fitmentSearchFieldsSelector(state);
    const fitmentFacets = new FacetCollection(
      response.Facets?.filter((facet) => fitmentFields?.includes(facet.FieldName)) || [],
      (v) => v.term !== 'Universal',
    );

    dispatch(
      setVerifyFitmentFacets(fitmentFacets, {
        isAutoVehicleSelectionDisabled:
          isAutoVehicleSelectionDisabled ?? fitmentSearchConfig.isAutoVehicleSelectionDisabled,
        onInit,
        vehicleJustDiscarded,
        vehicleJustSelected,
      }),
    );
    dispatch(setVerifyFitmentProduct(response.Items?.[0] ?? null));

    tryAutoSelectVerifyFitmentVehicle(arg, fitmentFacets, fitmentFields);
  });
}

function syncUrlWithVehicle(arg) {
  // Since facet selection pages don't send search requests and don't use search state,
  // we need to manually add the vehicle to the URL
  // In this particular place we can add a vehicle from response. We can't do it everywhere.
  // Check this PR for more information https://github.com/Convermax/SiteSearch/pull/6827

  if (fitmentSearchConfig.isOnCategorySelectionPage) {
    const responseVehicleSelection = createResponseVehicleSelector(arg.action.meta?.isolatedKey ?? null)(
      arg.state,
    )?.selection;

    const shouldUpdateUrl =
      (!fitmentSearchConfig.hideVehicleFromUrl || fitmentSearchConfig.doNotSaveSelectedVehicle) &&
      responseVehicleSelection?.length;

    if (shouldUpdateUrl) {
      const newLocation = getUriFromRequest(
        { selection: responseVehicleSelection },
        { pathname: window.location.pathname },
      );
      arg.dispatch(replaceInHistory(newLocation, null));
    }
  }
}

function tryAutoSelectVehicle(arg) {
  const {
    state,
    action: {
      meta: {
        isAutoVehicleSelectionDisabled,
        isolatedKey,
        isPartialMode,
        onInit,
        sendIsolatedRequest,
        doNotRedirectOnVehicleSelect,
        redirectUrl,
        vehicleWidgetName,
      } = {},
    },
  } = arg;

  const isSelectedVehicleNew = createIsServerChangedVehicleSelector(isolatedKey)(state);

  if (
    isSelectedVehicleNew &&
    !(isAutoVehicleSelectionDisabled ?? fitmentSearchConfig.isAutoVehicleSelectionDisabled)
  ) {
    const responseFitmentFacets = createFitmentSearchResponseFacetsSelector(isolatedKey)(state);
    const responseFitmentFacetsWithSelection = responseFitmentFacets?.filter((f) => f.selection?.length);

    const responseVehicle = createResponseVehicleSelector(isolatedKey)(state);
    const isSpecifyDialogOpened = isSpecifyDialogOpenedSelector(state);
    const isVehicleSelectionForced = isVehicleSelectionForcedSelector(state);

    if (isPartialMode) {
      const requiredFields = createRequiredFitmentSearchFieldsSelector(isolatedKey)(state);
      const extraFields = createFitmentSearchExtraFieldsSelector(isolatedKey)(state);

      const justChangedField = responseFitmentFacetsWithSelection?.at(
        responseFitmentFacetsWithSelection?.length - 1,
      ).field;

      const isJustChangedExtraField = extraFields && extraFields.includes(justChangedField);

      const allRequiredFieldsHaveSelection = createAllRequiredFieldsHaveSelectionSelector(isolatedKey)(state);

      // do not save the vehicle with only one field in partial mode
      if ((requiredFields?.length > 1 && allRequiredFieldsHaveSelection) || isJustChangedExtraField) {
        fitmentSearchConfig.onVehicleSelected(responseVehicle.filteredFieldMap);

        return vehicleSelected(responseVehicle, {
          isolatedKey,
          isSpecifyDialogOpened,
          isVehicleSelectionForced,
          isVehicleAutoSelected: true,
          onInit,
          sendIsolatedRequest,
          doNotRedirectOnVehicleSelect,
          redirectUrl,
          vehicleWidgetName,
        });
      }
    }

    const allResponseFitmentFacetsHaveSelection =
      responseFitmentFacetsWithSelection?.length === responseFitmentFacets?.length;

    if (allResponseFitmentFacetsHaveSelection) {
      fitmentSearchConfig.onVehicleSelected(responseVehicle.filteredFieldMap);

      return vehicleSelected(responseVehicle, {
        isolatedKey,
        isSpecifyDialogOpened,
        isVehicleSelectionForced,
        isVehicleAutoSelected: true,
        onInit,
        sendIsolatedRequest,
        doNotRedirectOnVehicleSelect,
        redirectUrl,
        vehicleWidgetName,
      });
    }
  }
}

function tryAutoSelectVerifyFitmentVehicle(arg, fitmentFacets, fitmentFields) {
  const { isAutoVehicleSelectionDisabled } = arg.action.meta || {};
  if (isAutoVehicleSelectionDisabled || arg.action.type === vehicleSelected.type) {
    return;
  }

  const selectedVehicle = selectedVehicleSelector(arg.state);
  const responseVehicle = new Vehicle(fitmentFacets.selection, fitmentFields);

  const isResponseVehicleNew = !selectedVehicle.equals(responseVehicle);
  const isAllFacetsSelected = !fitmentFacets.filter((f) => !f.selection.length).length;

  if (isResponseVehicleNew && isAllFacetsSelected) {
    fitmentSearchConfig.onVehicleSelected(responseVehicle.filteredFieldMap);

    arg.dispatch(
      vehicleSelected(responseVehicle, {
        isSelectedFromVerifyFitment: true,
        isVehicleAutoSelected: true,
        sendIsolatedRequest: true,
      }),
    );
  }
}

function onGarageUpdated(arg) {
  const { customerId } = window.Convermax;
  if (!customerId) {
    return;
  }

  const garage = garageDataSelector(arg.state).map((vehicle) => vehicle.filteredFieldMap);
  const selectedVehicle = selectedVehicleSelector(arg.state).filteredFieldMap;

  saveGarageToAccount(customerId, garage, selectedVehicle);
}

import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { fromPayload } from 'Core/reducers/common.ts';
import { setProductData } from 'Core/actions/product.ts';

import type { ServerModel } from 'Modules/serverApi/types.ts';

export default combineReducers({
  data: createReducer(null as ServerModel.SearchItem | null, (builder) =>
    builder.addCase(setProductData, fromPayload),
  ),
});

import { RefObject, useCallback } from 'react';

import useResizeState from 'Core/hooks/resizeState.js';

import type { Facet, FacetCollection } from 'Models';

const estimatedWidthPerSelect = 150;

export enum LayoutType {
  row = 'row',
  column = 'column',
}

export function useLayoutState(
  rootRef: RefObject<HTMLElement | undefined>,
  facets: FacetCollection,
  columnBreakpoint?: number,
  isAlwaysColumnLayout?: boolean,
  fields?: string[],
) {
  const selectCount = (fields ? facets.filter((facet: Facet) => fields.includes(facet.field)) : facets)
    .length;
  const dropdownMaxColumnWidth = columnBreakpoint ?? selectCount * estimatedWidthPerSelect;

  return useResizeState(
    useCallback(
      (state = isAlwaysColumnLayout ? LayoutType.column : LayoutType.row) =>
        isAlwaysColumnLayout
          ? LayoutType.column
          : rootRef.current?.offsetParent
            ? rootRef.current.clientWidth > dropdownMaxColumnWidth
              ? LayoutType.row
              : LayoutType.column
            : state,
      [dropdownMaxColumnWidth, isAlwaysColumnLayout, rootRef],
    ),
  );
}

export const getClassesByLayoutState = (layoutState: LayoutType) => {
  return layoutState === LayoutType.row
    ? ['cm_row', 'cm_vehicle-widget__row']
    : layoutState === LayoutType.column
      ? ['cm_column', 'cm_vehicle-widget__column']
      : [];
};

import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dialogOpened } from 'Core/actions/dialog.ts';
import { vehicleDiscardingRequested } from 'Core/actions/fitmentSearch/vehicle.ts';
import { clearVerifyFitmentRequest } from 'Core/actions/fitmentSearch/request.ts';
import {
  isVehicleSelectedSelector,
  selectedVehicleSelector,
  verifyFitmentProductSelector,
  verifyFitmentFacetsSelector,
  fitmentSearchFieldsSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { Vehicle } from 'Models';
import { getClassesByLayoutState, useLayoutState } from '../vehicleWidget/useLayoutState.tsx';
import VerifyFitmentDropdowns from './verifyFitmentDropdowns.tsx';
import VerifyFitmentLabel from './verifyFitmentLabel.tsx';
import VerifyFitmentSpecifier from './verifyFitmentSpecifier.tsx';

import type { TemplateFunction } from 'Components/types.ts';
import type { FacetCollection } from 'Models';
import type { Params as VehicleDropdownsParams } from './verifyFitmentDropdowns.tsx';
import type { Params as VehicleLabelParams } from './verifyFitmentLabel.tsx';
import type { Params as VehicleSpecifierParams } from './verifyFitmentSpecifier.tsx';
import type { VehicleValue } from 'Models/vehicle.ts';

type Params = VehicleDropdownsParams | VehicleLabelParams | VehicleSpecifierParams;

interface Props {
  template: TemplateFunction<Params>;
  name: string;
  isAlwaysColumnLayout: boolean;
  columnBreakpoint?: number;
  initCollapsed: boolean;
  isAutoVehicleSelectionDisabled?: boolean;
  globalVehicleDiscardEnabled?: boolean;
  useNativeDropdown?: boolean;
  useSearchableDropdown?: boolean;
}

const VerifyFitment: FC<Props> = ({
  template,
  isAlwaysColumnLayout = false,
  columnBreakpoint,
  name,
  initCollapsed,
  isAutoVehicleSelectionDisabled,
  globalVehicleDiscardEnabled,
  useNativeDropdown,
  useSearchableDropdown,
}) => {
  const rootRef = useRef<HTMLElement>(null);
  const dispatch = useDispatch();

  const [aboutToChange, setAboutToChange] = useState(false);

  const isVehicleSelected = useSelector(isVehicleSelectedSelector);

  const facets = useSelector(verifyFitmentFacetsSelector) as FacetCollection;

  const fitmentFields = useSelector(fitmentSearchFieldsSelector) as string[];
  const unselectedFacets = facets.filter((f) => !f.selection.length);

  const selectedVehicle = useSelector(selectedVehicleSelector);
  const responseVehicle = new Vehicle(facets.selection as VehicleValue[], fitmentFields);
  const isVehicleChangedOnResponse = !(
    selectedVehicle.equals(responseVehicle) ||
    (selectedVehicle.isMoreSpecificThan(responseVehicle) && !unselectedFacets.length)
  );

  const verifyFitmentProductData = useSelector(verifyFitmentProductSelector);
  const fitsTheVehicle = verifyFitmentProductData?.fitsTheVehicle;

  const layoutState = useLayoutState(rootRef, facets, columnBreakpoint, isAlwaysColumnLayout);
  const appendedClasses = [...getClassesByLayoutState(layoutState)];

  const labelTemplate = getLabelTemplateByFits(fitsTheVehicle);
  const isLockedTemplate =
    !aboutToChange && isVehicleSelected && !isVehicleChangedOnResponse && !unselectedFacets?.length;

  useEffect(
    function showLabelWhenVehicleChanges() {
      setAboutToChange(false);
    },
    [selectedVehicle],
  );

  if (
    !verifyFitmentProductData ||
    !Object.keys(verifyFitmentProductData).length ||
    fitsTheVehicle === 'nonVehicleProducts' ||
    (isLockedTemplate && !fitsTheVehicle)
  ) {
    return null;
  }

  const discardVehicle = () => {
    if (globalVehicleDiscardEnabled) {
      dispatch(vehicleDiscardingRequested({ globalVehicleDiscardEnabled }));
    } else {
      dispatch(clearVerifyFitmentRequest());
    }
  };
  const onChangeVehicle = () => setAboutToChange(true);
  const onSelectVehicle = () => setAboutToChange(false);
  const openDialog = () => dispatch(dialogOpened('VehicleWidgetDialog', { withCurrentVehicle: true }));

  if (!aboutToChange && isVehicleSelected && unselectedFacets?.length) {
    const props = {
      template,
      rootRef,
      appendedClasses,
      fitmentFields,
      unselectedFacets,
      vehicleString: responseVehicle.toString(),
      onSelectVehicle,
    };
    return <VerifyFitmentSpecifier {...props} />;
  }

  if (labelTemplate || isLockedTemplate) {
    const labelProps = {
      template,
      rootRef,
      templateName: labelTemplate || 'locked',
      appendedClasses,
      isVehicleSelected,
      responseVehicle,
      verifyFitmentProductData,
      discardVehicle,
      onChangeVehicle,
      openDialog,
    };

    return <VerifyFitmentLabel {...labelProps} />;
  }

  const dropdownsProps = {
    template,
    rootRef,
    appendedClasses,
    name,
    layoutState,
    facets,
    fitmentFields,
    responseVehicle,
    aboutToChange,
    allowToDiscardVehicle: facets.selection.length > 0,
    initCollapsed,
    isAllFieldsSelected: !unselectedFacets.length,
    isAutoVehicleSelectionDisabled,
    isVehicleSelected,
    useNativeDropdown,
    useSearchableDropdown,
    discardVehicle,
    onSelectVehicle,
    openDialog,
  };
  return <VerifyFitmentDropdowns {...dropdownsProps} />;
};

function getLabelTemplateByFits(fits: string) {
  switch (fits) {
    case 'universal':
    case 'unknown':
      return fits;
    default:
      return null;
  }
}

export default VerifyFitment;

import { combineReducers } from 'redux';
import { createNextState } from '@reduxjs/toolkit';

import { setResponse } from 'Core/actions/response.ts';
import {
  setFitmentFields,
  setFitmentSearchResponseFacets,
  setVerifyFitmentFacets,
} from 'Core/actions/fitmentSearch/index.js';
import { createReducer, createReducersFromShorthands } from 'Core/reducers/common.ts';
import { FacetCollection, FacetValue } from 'Models/index.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { equalByMany } from 'Utils/array.js';

export function updateVehicleSelection(selection, selectedValue, fitmentFields) {
  const updatedSelection = [...selection];

  const valueIndexToReplace = updatedSelection.findIndex((value) => value.field === selectedValue.field);

  if (valueIndexToReplace !== -1) {
    updatedSelection[valueIndexToReplace] = selectedValue;
  } else {
    const valueIndexToInsert = fitmentFields.indexOf(selectedValue.field);

    updatedSelection.splice(valueIndexToInsert, 0, selectedValue);
  }

  return updatedSelection;
}

const initStates = {
  error: null,
  facets: new FacetCollection(),
  totalHits: -1,
};
export const fitmentResponseReducer = combineReducers(
  createReducersFromShorthands(
    {
      error: { [setResponse.type]: getFromPayload('error') },
      facets: {
        [setResponse.type]: (_state, { payload: { Facets } }) =>
          new FacetCollection(Facets || [], (v) => v.term !== 'Universal'),
        [setFitmentSearchResponseFacets.type]: (_state, { payload }) =>
          new FacetCollection(payload || [], (v) => v.term !== 'Universal'),
        [setVerifyFitmentFacets.type]: (
          state,
          {
            payload,
            meta: { isAutoVehicleSelectionDisabled, onInit, vehicleJustDiscarded, vehicleJustSelected },
          },
        ) =>
          onInit ||
          vehicleJustDiscarded ||
          vehicleJustSelected ||
          (payload.filter((f) => !f.selection.length).length === 0 && !isAutoVehicleSelectionDisabled)
            ? payload
            : state,
      },
      totalHits: { [setResponse.type]: getFromPayload('TotalHits') },
    },
    initStates,
  ),
);

const fieldsInitState = {
  base: fitmentSearchConfig.baseFields,
  extra: fitmentSearchConfig.extraFields,
};
export const fitmentFieldsReducer = createReducer(
  { [setFitmentFields.type]: (state, { payload }) => payload || state },
  fieldsInitState,
);

function getFromPayload(key) {
  return (_state, { payload }) => payload[key] ?? null;
}

export function fixRequestDueToResponse(state, fix) {
  return (
    state &&
    createNextState(state, (draft) => {
      if (!fix) {
        return;
      }

      const { selection } = draft.request;
      const responseSelection = draft.response.facets.selection.filter((v) =>
        fitmentSearchConfig.fields.includes(v.field),
      );

      if (!equalByMany(selection, responseSelection, FacetValue.termKey, 'isUnique')) {
        draft.request.selection = responseSelection;
      }
    })
  );
}

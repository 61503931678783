import { useDispatch, useSelector } from 'react-redux';

import { createSelectRepeater, SelectContextProvider } from 'Components/smallComponents/select.tsx';
import { replaceVehicleValue, vehicleSelected } from 'Core/actions/fitmentSearch/index.js';
import {
  createFitmentSearchResponseSelectionSelector,
  createAllRequiredFieldsHaveSelectionSelector,
  fitmentSearchFieldsSelector,
  selectedVehicleSelector,
  createVerifyFitmentSpecifyFields,
} from 'Core/selectors/fitmentSearch/index.js';
import { cloneSafe } from 'Utils/components.ts';
import { FacetValue, Vehicle } from 'Models/index.ts';

export default function VehicleSpecifier({
  template,
  appendedClasses,
  rootRef,
  isolatedKey,
  onSelectVehicle,
}) {
  const dispatch = useDispatch();

  const selection = useSelector(createFitmentSearchResponseSelectionSelector(isolatedKey));
  const isAllRequiredFieldHasSelection = useSelector(
    createAllRequiredFieldsHaveSelectionSelector(isolatedKey),
  );
  const facets = useSelector(createVerifyFitmentSpecifyFields(isolatedKey));
  const vehicleString = useSelector((state) => selectedVehicleSelector(state).toString());
  const fitmentFields = useSelector(fitmentSearchFieldsSelector);

  const selects = createSelectRepeater(
    facets.map(({ field, name, facetedValues }) =>
      facetedValues.length
        ? {
            entries: facetedValues.map((value) => ({
              value: value.value,
              term: value.term,
              selected: FacetValue.isInCollection(value, selection),
            })),
            extraClassName: 'cm_vehicle-widget_select',
            title: name,
            hideNullOption: true,
            key: field,
            onChange: (term) =>
              dispatch(
                replaceVehicleValue(
                  facetedValues.find((v) => v.term === term),
                  isolatedKey,
                  fitmentFields,
                ),
              ),
          }
        : null,
    ),
  );

  const selectVehicle = () => {
    dispatch(vehicleSelected(new Vehicle(selection, fitmentFields), { isolatedKey }));
    onSelectVehicle();
  };

  const component = template.call({
    allowToSetVehicle: isAllRequiredFieldHasSelection,
    vehicleString,
    selects,
    fieldsToSpecify: facets.map((f) => f.name),
    selectVehicle,
    template: 'inexact',
  });

  return <SelectContextProvider>{cloneSafe(component, rootRef, { appendedClasses })}</SelectContextProvider>;
}

import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import {
  clearVerifyFitmentRequest,
  garageCleanupRequested,
  removalFromGarageRequested,
  setVerifyFitmentFacets,
  setVerifyFitmentProduct,
  setVerifyFitmentProductId,
  replaceVerifyFitmentVehicleValue,
  vehicleDiscardingRequested,
  vehicleSelected,
} from 'Core/actions/fitmentSearch/index.js';
import { FacetCollection, FacetRequest } from 'Models';
import getVehicleFromUri from 'Modules/converter/getVehicleFromUri.js';
import productConfig from 'Models/uiConfig/productConfig.js';
import { loadSelectedVehicle } from 'Utils/userStorage.js';
import { fixRequestDueToResponse, updateVehicleSelection } from './common.js';

import type { ServerModel } from 'Modules/serverApi/types.ts';
import type { AnyAction } from 'redux';

type VerifyFitmentReducer = {
  productId: number | null;
  request: { selection: FacetRequest[] };
  response: { facets: FacetCollection | null; product: ServerModel.SearchItem | null };
};

const vehicleFromUriSelection = getVehicleFromUri().selection;
const initRequestSelection = vehicleFromUriSelection.length
  ? vehicleFromUriSelection
  : loadSelectedVehicle().selection;

const verifyFitmentReducer = combineReducers({
  productId: createReducer(productConfig.localItemId as number | null, (builder) => {
    builder.addCase(setVerifyFitmentProductId, (_state, { payload }) => payload);
  }),
  request: combineReducers({
    selection: createReducer(initRequestSelection as FacetRequest[], (builder) => {
      builder
        .addCase(clearVerifyFitmentRequest, () => [])
        .addCase(garageCleanupRequested, () => [])
        .addCase(vehicleDiscardingRequested, () => [])
        .addCase(removalFromGarageRequested, (state, { meta }) => (meta.wasSelected ? [] : state))
        .addCase(replaceVerifyFitmentVehicleValue, (state, { payload, meta: { fitmentFields } }) =>
          updateVehicleSelection(state, payload, fitmentFields),
        )
        .addCase(vehicleSelected, (_state, { payload: { selection } }) => selection);
    }),
  }),
  response: combineReducers({
    facets: createReducer(new FacetCollection() as FacetCollection | null, (builder) => {
      builder.addCase(setVerifyFitmentFacets, (_state, { payload }) => payload);
    }),
    product: createReducer(null as ServerModel.SearchItem | null, (builder) => {
      builder.addCase(setVerifyFitmentProduct, (_state, { payload }) => payload);
    }),
  }),
});

const verifyFitment = (state: VerifyFitmentReducer, action: AnyAction) =>
  verifyFitmentReducer(
    fixRequestDueToResponse(state, action.type === replaceVerifyFitmentVehicleValue.type),
    action,
  );

export default verifyFitment;

import { createSelector } from '@reduxjs/toolkit';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { garageCleanupRequested, vehicleDiscardingRequested } from 'Core/actions/fitmentSearch/index.js';
import { garageDataSelector } from 'Core/selectors/fitmentSearch/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import GarageItem from './garageItem.ts';
import AddButton from '../addButton.jsx';
import { vehicleWidgetName } from '../garage.jsx';
import GarageContext from '../garageContext.ts';
import VehicleWidget from '../../vehicleWidget/index.ts';

export default function ListDropdown({ template }) {
  const dispatch = useDispatch();

  const {
    closeGarage,
    doNotRedirectOnVehicleSelect,
    isAutoVehicleSelectionDisabled,
    showVehicleDropdowns,
    useNativeDropdown,
    useVehicleWidget,
    isVehicleSelected,
  } = useContext(GarageContext);

  const garageItems = useSelector(
    createSelector(garageDataSelector, (data) => data.repeaterComponents(GarageItem)),
  );

  const clearGarage = () => {
    closeGarage();
    dispatch(garageCleanupRequested());
    fitmentSearchConfig.onVehicleDiscarded();
  };

  const discardSelectedVehicle = () => {
    dispatch(vehicleDiscardingRequested());
    fitmentSearchConfig.onVehicleDiscarded();
  };

  const onClick = simpleHandler({ clearGarage, showVehicleDropdowns });

  const addButton = (templ) => <AddButton template={templ} key="add" />;

  const vehicleWidget = (templ) => {
    if (!useVehicleWidget) {
      return null;
    }

    const props = {
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
      isAutoVehicleSelectionDisabled,
      name: vehicleWidgetName,
      onSelectVehicle: () => closeGarage(),
      doNotRedirectOnVehicleSelect,
      useNativeDropdown,
      template: templ,
    };
    return <VehicleWidget {...props} key="VehicleWidget" />;
  };

  const component = template.call({
    addButton,
    closeGarage,
    vehicleWidget,
    items: garageItems,
    isVehicleSelected,
    discardSelectedVehicle,
    template: 'list',
  });
  return cloneSafe(component, null, { onClick });
}

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Pagination, PaginationMenu } from 'Components/pagination/index.ts';
import { Message, CustomMessage } from 'Components/message/index.js';
import { useIdleEffect } from 'Core/hooks/index.js';
import {
  pageFromSelector,
  pageToSelector,
  searchQuerySelector,
  searchResponseStateSelector,
  searchResponseExtraSelector,
  totalHitsSelector,
  searchResponseMessagesSelector,
} from 'Core/selectors/search.js';
import { isVehicleSelectedSelector } from 'Core/selectors/fitmentSearch/index.js';
import { resultsLoadingSelector, viewSelector } from 'Core/selectors/show.ts';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import isMobile from 'Vendor/isMobile.js';

export default function SearchPage({ context, template, updateCallback = () => {} }) {
  const responseState = useSelector(searchResponseStateSelector);
  const responseQuery = useSelector(searchQuerySelector);
  const responseExtra = useSelector(searchResponseExtraSelector);
  const pageFrom = useSelector(pageFromSelector);
  const pageTo = useSelector(pageToSelector);
  const totalHits = useSelector(totalHitsSelector);
  const view = useSelector(viewSelector);
  const isLoading = useSelector(resultsLoadingSelector);
  const isVehicleSelected = useSelector(isVehicleSelectedSelector);
  const responseMessages = useSelector(searchResponseMessagesSelector);

  useEffect(() => {
    window.document.body.classList.add('cm_hasSearchPage');
  }, []);

  useIdleEffect(updateCallback);

  const message = (templ) => <Message template={templ} key="message" />;
  const customMessage = (templ) => <CustomMessage template={templ} key="custom-message" />;
  const pagination = (templ, { widgetName }) => (
    <Pagination template={templ} key={widgetName || 'pagination'} />
  );
  const paginationMenu = (templ, { widgetName }) => (
    <PaginationMenu template={templ} key={widgetName || 'pagination-menu'} />
  );

  return template.call({
    ...context,
    pagination,
    paginationMenu,
    message,
    customMessage,
    isLoading,
    isMobile,
    responseState,
    responseQuery,
    responseExtra,
    searchPageUrl: uiConfig.searchPageUrl,
    pageFrom,
    pageTo,
    totalHits,
    view,
    isVehicleSelected,
    responseMessages,
  });
}

import { combineReducers } from 'redux';

import { replaceVehicleValue } from 'Core/actions/fitmentSearch/index.js';
import { createIsolatedReducer } from 'Core/reducers/common.ts';
import { fitmentFieldsReducer, fitmentResponseReducer, fixRequestDueToResponse } from './common.js';
import isolatedDialog from './isolatedDialog/index.js';
import isolatedHeader from './isolatedHeader/index.js';
import isolatedKey from './isolatedKey.js';
import request from './request.js';
import smallReducers from './smallReducers.js';
import storage from './storage.ts';
import verifyFitment from './verifyFitment.ts';

const baseFitmentSearch = combineReducers({
  request: createIsolatedReducer(isolatedKey, request),
  response: createIsolatedReducer(isolatedKey, fitmentResponseReducer),
  fields: createIsolatedReducer(isolatedKey, fitmentFieldsReducer),
  storage,
  isolatedDialog,
  isolatedHeader,
  verifyFitment,
  ...smallReducers,
});

const fitmentSearch: typeof baseFitmentSearch = (state, action) =>
  baseFitmentSearch(fixRequestDueToResponse(state, action.type === replaceVehicleValue.type), action);

export default fitmentSearch;

import { vehicleSelected } from 'Core/actions/fitmentSearch/index.js';
import { assignLocation } from 'Core/actions/redirect.ts';
import { epicFromHandlers } from 'Core/epics/common.js';
import { selectedVehicleSelector } from 'Core/selectors/fitmentSearch/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { getUriFromRequest } from 'Modules/converter/index.js';
import { search } from 'Modules/serverApi/index.js';

export default epicFromHandlers({
  async [vehicleSelected]({
    state,
    action: {
      meta: { allowRedirect, extra, redirectUrl },
    },
  }) {
    if (allowRedirect) {
      const { selection: selectedVehicleSelection } = selectedVehicleSelector(state);

      if (fitmentSearchConfig.expectResponseRedirect) {
        // TODO: get rid of this request
        const { Actions: [{ Type, RedirectUrl } = {}] = [] } = await search({
          selection: selectedVehicleSelection,
          extra,
        });

        if (Type === 'Redirect' && RedirectUrl && window.location.pathname !== RedirectUrl) {
          return assignLocation(RedirectUrl);
        }
      }

      if (redirectUrl) {
        if (window.location.pathname !== redirectUrl) {
          return assignLocation(
            getUriFromRequest({ selection: selectedVehicleSelection }, { pathname: redirectUrl }).href,
          );
        }
      } else {
        const {
          categorySelectionPageUrl,
          isCategorySelectionPageRedirectEnabled,
          isOnCategorySelectionPage,
        } = fitmentSearchConfig;

        if (!isCategorySelectionPageRedirectEnabled || !categorySelectionPageUrl) {
          const searchPageRedirectUri = getUriFromRequest(
            { selection: selectedVehicleSelection },
            { goToSearchPage: true },
          );

          if (window.location.pathname !== searchPageRedirectUri.pathname) {
            return assignLocation(searchPageRedirectUri.href);
          }
        }

        if (isCategorySelectionPageRedirectEnabled && !isOnCategorySelectionPage) {
          return assignLocation(
            getUriFromRequest({ selection: selectedVehicleSelection }, { pathname: categorySelectionPageUrl })
              .href,
          );
        }
      }
    }
  },
});

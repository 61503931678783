import { createSelector } from '@reduxjs/toolkit';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setNextPage } from 'Core/actions/request.js';
import {
  allItemsReceivedSelector,
  lastPageNumberSelector,
  noItemsReceivedSelector,
  searchRequestSelector,
} from 'Core/selectors/search.js';
import { useDropdown } from 'Core/hooks/index.js';
import { getUriFromRequest } from 'Modules/converter/index.js';
import { cloneSafe } from 'Utils/components.ts';
import PaginationButton from './paginationButton.js';
import PaginationDropdown from './paginationDropdown.jsx';
import { makeButtonProps, pageProps } from './propsHelper.js';

const hidePaginationSelector = createSelector(
  noItemsReceivedSelector,
  allItemsReceivedSelector,
  (noItemsReceived, allItemsReceived) => noItemsReceived || allItemsReceived,
);

const dropdownName = 'paginationDropdown';

export default function PaginationMenu({ template }) {
  const dispatch = useDispatch();
  const hidePagination = useSelector(hidePaginationSelector);
  const pageNumber = useSelector((state) => state.search.request.pageNumber);
  const lastPageNumber = useSelector(lastPageNumberSelector);

  const searchRequest = useSelector(searchRequestSelector);
  const searchParams = getUriFromRequest(searchRequest).search;

  const rootRef = useRef();

  const { hideDropdown } = useDropdown(rootRef, '.cm_paginationMenu', {
    dropdownName,
    toggle: true,
  });

  if (hidePagination) {
    return null;
  }

  const buttons = makeButtonProps(pageNumber, lastPageNumber, true).map((buttonProps) => (templ) => {
    const props = {
      ...buttonProps,
      searchParams,
      template: templ,
      isActive: buttonProps.isActive || buttonProps.pageNumber === pageNumber,
      hideDropdown,
    };
    return <PaginationButton {...props} />;
  });

  const pages = Array.apply(0, Array(lastPageNumber + 1))
    .map((v, k) => pageProps(k, lastPageNumber))
    .map((buttonProps) => ({
      ...buttonProps,
      searchParams,
      isActive: buttonProps.isActive || buttonProps.pageNumber === pageNumber,
      hideDropdown,
    }));

  const dropdown = (templ) => <PaginationDropdown template={templ} pages={pages} key="dropdown" />;

  const component = template.call({
    buttons,
    hasNextPage: lastPageNumber > pageNumber,
    loadNextPage: () => dispatch(setNextPage()),
    [dropdownName]: dropdown,
  });

  return cloneSafe(component, rootRef);
}

import { useDispatch, useSelector } from 'react-redux';

import DialogButton from 'Components/smallComponents/dialogButton.js';
import { vehicleDiscardingRequested, vehicleSelected } from 'Core/actions/fitmentSearch/index.js';
import { selectedVehicleSelector } from 'Core/selectors/fitmentSearch/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';

export default function SingleVehicleGarage({ template }) {
  const dispatch = useDispatch();
  const selectedVehicle = useSelector(selectedVehicleSelector);
  const templateParams = {
    vehicleString: selectedVehicle.toString(),
    vehicle: selectedVehicle.filteredFieldMap,
    template: selectedVehicle.notNull ? 'locked' : 'active',
  };

  const discardVehicle = () => {
    dispatch(vehicleDiscardingRequested());
    fitmentSearchConfig.onVehicleDiscarded();
  };
  const goToResults = () => {
    dispatch(vehicleSelected(selectedVehicle));
    fitmentSearchConfig.onVehicleSelected(selectedVehicle.filteredFieldMap);
  };

  const onClick = simpleHandler({ discardVehicle, goToResults });

  const changeButton = (templ) => {
    const props = {
      template: templ,
      templateParams,
      dialogName: 'VehicleWidgetDialog',
      metadata: { withCurrentVehicle: true },
      key: 'change-button',
    };
    return <DialogButton {...props} />;
  };

  const component = template.call({ changeButton, ...templateParams });
  return cloneSafe(component, null, { onClick });
}

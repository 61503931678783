import { useLayoutEffect, useRef, useState, React } from 'react';

import { cloneSafe } from 'Utils/components.ts';
import PaginationButton from './paginationButton.js';

const itemHeight = 30; // props? options?
const viewportHeight = 200;
const visibleItemsCount = Math.trunc(viewportHeight / itemHeight);

export default function PaginationDropdown({ template, pages }) {
  const rootRef = useRef();

  const [currentSlot, setCurrentSlot] = useState({
    start: 0,
    end: visibleItemsCount,
  });

  const containerStyle = { height: pages.length * itemHeight };

  useLayoutEffect(function () {
    const root = rootRef.current;
    const elem = root?.querySelector('.cm_scroll-container');
    if (elem instanceof HTMLElement) {
      elem.style.height = `${containerStyle.height}px`;
    }
  });

  const onViewportScroll = () => {
    const root = rootRef.current;
    const viewport = root?.querySelector('.cm_scroll-viewport');
    if (viewport instanceof HTMLElement) {
      const zeroBound = Math.trunc(viewport.scrollTop / itemHeight);
      const topBound = zeroBound - visibleItemsCount;
      const currentStart = topBound >= pages.length ? topBound : zeroBound;
      if (currentStart !== currentSlot.start) {
        const bottomBound = currentStart + visibleItemsCount;
        setCurrentSlot({
          start: currentStart,
          end: bottomBound >= pages.length ? pages.length - 1 : bottomBound,
        });
      }
    }
  };

  const visiblePages = [];
  for (let i = currentSlot.start; i <= currentSlot.end; i++) {
    visiblePages.push((templ) => (
      <PaginationButton template={templ} style={{ top: i * itemHeight, height: itemHeight }} {...pages[i]} />
    ));
  }

  const component = template.call({ pages: visiblePages, onViewportScroll });

  return cloneSafe(component, rootRef);
}

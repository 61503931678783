import { vehicleSelected } from 'Core/actions/fitmentSearch';
import {
  assignLocation,
  goBackInHistory,
  pushToHistory,
  replaceInHistory,
  replaceLocation,
} from 'Core/actions/redirect.ts';
import { epicFromHandlers } from 'Core/epics/common.js';
import history from 'Core/history.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig';
import uiConfig from 'Models/uiConfig/uiConfig';
import { getUriFromRequest } from 'Modules/converter';

export default epicFromHandlers({
  [assignLocation.type]: ({ action: { payload } }) => window.location.assign(payload),
  [replaceLocation.type]: ({ action: { payload } }) => window.location.replace(payload),
  [goBackInHistory.type]: () => history.back(),
  [pushToHistory.type]: ({
    action: {
      payload: { location, state },
    },
  }) =>
    // sometimes history manipulations force style recalculation so it's better to do it a bit later
    void setTimeout(() => {
      history.push(location, state);
      window.ga?.('send', 'pageview', window.location.pathname + window.location.search);
    }, 0),
  [replaceInHistory.type]: ({
    action: {
      payload: { location, state },
    },
  }) =>
    void setTimeout(() => {
      history.replace(location, state);
    }, 0),
});

export function tryResponseRedirect(arg, response) {
  const {
    dispatch,
    action: { meta: { doNotRedirectOnVehicleSelect, onInit } = {}, type: actionType },
  } = arg;

  const isVehicleSelectedAction = actionType === vehicleSelected.type;

  if (isVehicleSelectedAction && (onInit || doNotRedirectOnVehicleSelect)) {
    return false;
  }

  const { Actions: [{ Type, RedirectUrl } = {}] = [] } = response;
  if (
    Type === 'Redirect' &&
    RedirectUrl &&
    !(isVehicleSelectedAction && fitmentSearchConfig.disableServerRedirects)
  ) {
    const url = new URL(RedirectUrl, window.location.origin);
    if (url.pathname === '/' && url.search) {
      // when receiving only search params from the server, redirect to the search page with them
      dispatch(assignLocation(`${uiConfig.searchPageUrl}${RedirectUrl}`));
      return true;
    } else if (window.location.pathname !== RedirectUrl) {
      dispatch(assignLocation(RedirectUrl));
      return true;
    }

    return false;
  }

  return isVehicleSelectedAction ? tryVehicleRedirect(arg) : false;
}

export function tryVehicleRedirect(arg) {
  const {
    dispatch,
    action: {
      payload: selectedVehicle,
      meta: {
        isSpecifyDialogOpened,
        isSelectedFromDialog,
        isSelectedFromGarage,
        isSelectedFromHeader,
        isSelectedFromSearch,
        isVehicleSelectionForced,
        isVehicleAutoSelected,
        redirectUrl,
      } = {},
    },
  } = arg;

  const {
    categorySelectionPageUrl,
    isAutoRedirectDisabled,
    isCategorySelectionPageRedirectEnabled,
    isOnCategorySelectionPage,
  } = fitmentSearchConfig;

  const isSelectedFromIsolatedHeader = isSelectedFromHeader && !isOnCategorySelectionPage;
  const isSelectedFromIsolatedDialog =
    isSelectedFromDialog && !isSpecifyDialogOpened && !isVehicleSelectionForced;
  const isSelectedFromSearchWithRedirect = isSelectedFromSearch && redirectUrl;

  const isWidgetRedirectAllowed =
    isSelectedFromIsolatedHeader ||
    isSelectedFromIsolatedDialog ||
    isSelectedFromGarage ||
    isSelectedFromSearchWithRedirect;

  const isVehicleSelectionRedirectAllowed =
    !isVehicleAutoSelected || (isVehicleAutoSelected && !isAutoRedirectDisabled);

  if (isWidgetRedirectAllowed && isVehicleSelectionRedirectAllowed) {
    const selectedVehicleSelection = selectedVehicle.selection;

    if (isSelectedFromSearch) {
      // check only for exact on the search results
      return tryVehicleRedirectByUrl(dispatch, redirectUrl, selectedVehicleSelection);
    }

    if (tryVehicleRedirectByUrl(dispatch, redirectUrl, selectedVehicleSelection)) {
      return true;
    }

    if (!isCategorySelectionPageRedirectEnabled || !categorySelectionPageUrl) {
      const searchPageRedirectUri = getUriFromRequest(
        { selection: selectedVehicleSelection },
        { goToSearchPage: true },
      );

      if (window.location.pathname !== searchPageRedirectUri.pathname) {
        dispatch(assignLocation(searchPageRedirectUri.href));
        return true;
      }

      return false;
    }

    if (isCategorySelectionPageRedirectEnabled && !isOnCategorySelectionPage) {
      dispatch(
        assignLocation(
          getUriFromRequest({ selection: selectedVehicleSelection }, { pathname: categorySelectionPageUrl })
            .href,
        ),
      );

      return true;
    }
  }

  return false;
}

function tryVehicleRedirectByUrl(dispatch, redirectUrl, selectedVehicleSelection) {
  if (redirectUrl && window.location.pathname !== redirectUrl) {
    dispatch(
      assignLocation(
        getUriFromRequest({ selection: selectedVehicleSelection }, { pathname: redirectUrl }).href,
      ),
    );

    return true;
  }

  return false;
}

import { combineReducers } from 'redux';

import { setResponse } from 'Core/actions/response.ts';
import { createReducersFromShorthands } from 'Core/reducers/common.ts';
import { FacetCollection, SearchItemDataCollection } from 'Models/index.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';

const { customFacetValueFilter: customFilter } = uiConfig;

const normalStateMap = {
  Corrected: 'corrected',
  Phonetic: 'corrected',
  Nothing: 'nothing',
  Normal: 'normal',
  Partial: 'partial',
};

const initStates = {
  error: null,
  facets: new FacetCollection(),
  items: new SearchItemDataCollection(),
  messages: [],
  originalQuery: '',
  query: '',
  state: '',
  totalHits: -1,
  extra: null,
};

export default combineReducers(
  createReducersFromShorthands(
    {
      error: { [setResponse.type]: getFromPayload('error') },
      facets: {
        [setResponse.type]: (_state, { payload: { Facets }, meta: { preselection } }) =>
          prepareResponseFacets(
            Facets,
            preselection,
            (f) => !fitmentSearchConfig.fields.includes(f.FieldName),
          ),
      },
      items: {
        [setResponse.type]: (state, { payload: { Items }, meta: { appendItems } }) => {
          const newItems = new SearchItemDataCollection(Items, 'search');
          return appendItems ? state.concat(newItems) : newItems;
        },
      },
      messages: { [setResponse.type]: getFromPayload('Messages', []) },
      originalQuery: { [setResponse.type]: getFromPayload('OriginalQuery') },
      query: { [setResponse.type]: getFromPayload('Query') },
      state: {
        [setResponse.type]: (_state, { payload: { State } }) => normalStateMap[State.split(',')[0]] || State,
      },
      totalHits: { [setResponse.type]: getFromPayload('TotalHits') },
      extra: { [setResponse.type]: getFromPayload('Extra') },
    },
    initStates,
  ),
);

function getFromPayload(key, defaultValue = null) {
  return (_state, { payload }) => payload[key] ?? defaultValue;
}

export function prepareResponseFacets(facets, preselection, filter) {
  /* 
    defaultFilter: Don't display preselected facets without the treeLevel.
    Example: the brand PLP will have the 'Brands' preselected facet. There will be only one selected value
    in the response, so don't display this facet with only one selected value which can't be unselected.
  */
  const preselectedFields = preselection.map((v) => v.field);
  const defaultFilter = (v) => !(v.treeLevel === null && preselectedFields.includes(v.field));
  const facetValueFilter = customFilter
    ? (v) => customFilter(v, { filter: defaultFilter, preselection })
    : defaultFilter;

  // preselected using 'getLocalPreselection' functions in platforms and config.js files
  const preselectedTreeLevelMap = Object.fromEntries(
    preselection.filter((v) => typeof v.treeLevel === 'number').map((v) => [v.field, v.treeLevel + 1]),
  );

  /* 
    v.TreeLevel can be predefined in 'responseHandler' functions.
    For example, to fix the TreeLevel of preselected in 'ShopifyPreselectionPlugin' values
    in `Client/platforms/shopify/requestConfig.js` file
  */
  const predefinedTreeLevelMap = Object.fromEntries(
    facets
      .filter((v) => v.IsTree && typeof v.TreeLevel === 'number')
      .map((v) => [v.FieldName, v.TreeLevel + 1]),
  );

  const treeLevelMap = { ...preselectedTreeLevelMap, ...predefinedTreeLevelMap };

  const treeLevelMapFieds = Object.keys(treeLevelMap);
  const otherTreeLevelMap = Object.fromEntries(
    facets
      .filter((facet) => facet.IsTree && !treeLevelMapFieds.includes(facet.FieldName) && facet.Values.length)
      /* 
        Do '.length - 1' because not preselected facets must show their zero levels
        since they start at zero level, while preselected facets will always preselect
        at least zero level, so they must start at least at the first level.
      */
      .map((f) => [f.FieldName, Math.min(...f.Values.map((v) => v.Term.split(f.TreeSeparator).length - 1))]),
  );

  const baseTreeLevel = { ...treeLevelMap, ...otherTreeLevelMap };

  const responseFacets = filter ? facets?.filter((f) => filter(f)) : facets;

  return new FacetCollection(responseFacets, facetValueFilter, baseTreeLevel);
}

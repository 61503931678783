import { useDispatch } from 'react-redux';

import { createSelectRepeater, SelectContextProvider } from 'Components/smallComponents/select.tsx';
import { replaceVerifyFitmentVehicleValue } from 'Core/actions/fitmentSearch/request.ts';
import { vehicleSelected } from 'Core/actions/fitmentSearch/vehicle.ts';
import { Vehicle } from 'Models/index.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { cloneSafe } from 'Utils/components.ts';
import { useCollapsing } from '../vehicleWidget/useCollapsing.tsx';

import type { RefObject } from 'react';
import type { Params as SelectParams } from 'Components/smallComponents/select.tsx';
import type { RepeaterFunctionInvoker, TemplateFunction } from 'Components/types.ts';
import type { FacetCollection } from 'Models/index.ts';
import type { CloneSafeOptionsType } from 'Utils/components.ts';
import type { LayoutType } from '../vehicleWidget/useLayoutState.tsx';

export type Params = {
  name: string;
  template: string;
  selects: RepeaterFunctionInvoker<SelectParams>;
  fitmentSearchTitle: string;
  aboutToChange: boolean;
  allowToDiscardVehicle: boolean;
  allowToSetVehicle: boolean;
  vehicleString: string;
  discardVehicle: () => void;
  openDialog: () => void;
  selectVehicle: () => void;
} & ReturnType<typeof useCollapsing>;

type Props = {
  template: TemplateFunction<Params>;
  rootRef: RefObject<HTMLElement>;
  appendedClasses: CloneSafeOptionsType['appendedClasses'];
  name: string;
  layoutState: LayoutType;
  facets: FacetCollection;
  fitmentFields: string[];
  responseVehicle: Vehicle;
  aboutToChange: boolean;
  allowToDiscardVehicle: boolean;
  initCollapsed: boolean;
  isAllFieldsSelected: boolean;
  isAutoVehicleSelectionDisabled?: boolean;
  isVehicleSelected: boolean;
  useNativeDropdown?: boolean;
  useSearchableDropdown?: boolean;
  discardVehicle: () => void;
  onSelectVehicle: () => void;
  openDialog: () => void;
};

export default function VerifyFitmentDropdowns({
  template,
  rootRef,
  appendedClasses,
  name: widgetName,
  layoutState,
  facets,
  fitmentFields,
  responseVehicle,
  aboutToChange,
  allowToDiscardVehicle,
  initCollapsed,
  isAllFieldsSelected,
  isAutoVehicleSelectionDisabled,
  isVehicleSelected,
  useNativeDropdown,
  useSearchableDropdown,
  discardVehicle: discardVehicleProp,
  onSelectVehicle,
  openDialog,
}: Props) {
  const dispatch = useDispatch();

  const collapsing = useCollapsing(rootRef, isVehicleSelected, layoutState, initCollapsed, aboutToChange);

  if (!facets.length) {
    return;
  }

  const selectVehicle = () => {
    if (isAllFieldsSelected) {
      dispatch(
        vehicleSelected(responseVehicle, { isSelectedFromVerifyFitment: true, sendIsolatedRequest: true }),
      );

      fitmentSearchConfig.onVehicleSelected(responseVehicle.filteredFieldMap);
      onSelectVehicle();

      if (!collapsing.collapsed) {
        collapsing.toggleCollapsed();
      }
    }
  };
  const discardVehicle = () => {
    discardVehicleProp();

    if (isAllFieldsSelected) {
      fitmentSearchConfig.onVehicleDiscarded();
    }
  };

  const selects = createSelectRepeater(
    facets.map(({ field, name, facetedValues }, index) => ({
      entries: facetedValues.map((v) => ({
        value: v.value,
        term: v.term,
        payload: v.payload,
        selected: v.isSelected,
      })),
      extraClassName: 'cm_vehicle-widget_select',
      disabled: !facetedValues.length,
      hideNullOption: true,
      field,
      title: name,
      key: field,
      index,
      useNativeDropdown: !useSearchableDropdown && useNativeDropdown,
      isSearchable: useSearchableDropdown,
      onChange: (term) => {
        const facetValue = facetedValues.find((v) => v.term === term);
        if (facetValue) {
          dispatch(
            replaceVerifyFitmentVehicleValue(facetValue, { isAutoVehicleSelectionDisabled, fitmentFields }),
          );
        }
      },
    })),
  );

  const component = template.call({
    name: widgetName,
    selects,
    vehicleString: responseVehicle.toString(),
    allowToSetVehicle: isAllFieldsSelected,
    allowToDiscardVehicle,
    aboutToChange,
    discardVehicle,
    selectVehicle,
    openDialog,
    fitmentSearchTitle: fitmentSearchConfig.fitmentSearchTitle,
    template: 'active',
    ...collapsing,
  });

  return <SelectContextProvider>{cloneSafe(component, rootRef, { appendedClasses })}</SelectContextProvider>;
}

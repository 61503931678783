export default {
  compared: 'cm_temp-key-while-we-fix-item-format-sync',
  forceVehicleSelectionLater: 'cm_force-vehicle-selection-later',
  garageData: 'cm_variant-garage',
  productsHistory: 'cm_productsHistory',
  deferedEvents: 'cm_deferedEvents',
  queriesHistory: 'cm_queriesHistory',
  vehicle: 'cm_variant-model',
  view: 'cm_view',
  isLoggedIn: 'cm_is-logged-in',
  facetToggleField: (f) => `cm_is-facet-toggled-${f}`,
};

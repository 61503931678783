import { CustomErrorType } from 'Models/index.ts';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { convertibleFromObject } from 'Modules/converter/index.js';
import { facetsToConvertible, makeGetOrDefault, sortToConvertible } from './common/convert.js';
import { GETBACKUP, GET, getErrorSearchResponse } from './common/httpClient.js';

const { requestDefaults, responseHandler } = requestConfig;
const endpoint = 'search.json';

export default async function search(request, abortSignal) {
  const getOrDefault = makeGetOrDefault(request, requestDefaults);
  const extra = { ...requestDefaults.extra, ...request.extra };

  const params = {
    query: encodeURIComponent(getOrDefault('query')),
    hiddenquery: encodeURIComponent(getOrDefault('hiddenQuery')),
    filterquery: encodeURIComponent(getOrDefault('filterQuery')),
    querycorrectionlevel: getOrDefault('queryCorrectionLevel'),
    page: getOrDefault('pageNumber'),
    pagesize: request.pageSize === 'all' ? 10000 : request.pageSize,
    mode: encodeURIComponent(getOrDefault('mode')),
    sort: sortToConvertible(request.sort),
    catalog: encodeURIComponent(getOrDefault('catalog')),
    facet: facetsToConvertible(request, requestDefaults),
    extra: await convertibleFromObject(extra),
    variantsMode: encodeURIComponent(getOrDefault('variantsMode')),
    referrer: getOrDefault('referrer'),
  };

  try {
    return responseHandler(await GET(endpoint, params, { signal: abortSignal }));
  } catch (err) {
    if (err?.type !== CustomErrorType.requestAborted) {
      console.warn(`The search request failed: ${err?.message}`);
    }

    if (err.message !== 'Failed to fetch') {
      // general network error with no status code can be handled here
    }

    try {
      return responseHandler(await GETBACKUP(endpoint, params, { signal: abortSignal }));
    } catch (err) {
      const message = await getErrorSearchResponse(err);
      return message;
    }
  }
}

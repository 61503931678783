import { getUriFromRequest } from 'Modules/converter/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { cloneSafe } from 'Utils/components.ts';

import type { RefObject } from 'react';
import type { TemplateFunction } from 'Components/types.ts';
import type { Vehicle } from 'Models/index.ts';
import type { CloneSafeOptionsType } from 'Utils/components.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';
import type { VehicleData } from 'Models/vehicle.ts';

export type Params = {
  template: string;
  isVehicleSelected: boolean;
  fitmentSearchTitle: string;
  fits: string | undefined;
  productData: ServerModel.SearchItem;
  vehicle: Record<string, string>;
  vehicleString: string;
  vehicleValues: VehicleData;
  changeVehicle: () => void;
  discardVehicle: () => void;
  getVehicleUrl: (pathname: string) => URL;
  openDialog: () => void;
};

type Props = {
  template: TemplateFunction<Params>;
  rootRef: RefObject<HTMLElement>;
  templateName: string;
  appendedClasses: CloneSafeOptionsType['appendedClasses'];
  isVehicleSelected: boolean;
  responseVehicle: Vehicle;
  verifyFitmentProductData: ServerModel.SearchItem;
  discardVehicle: () => void;
  onChangeVehicle: () => void;
  openDialog: () => void;
};

export default function VerifyFitmentLabel({
  template,
  rootRef,
  templateName,
  appendedClasses,
  isVehicleSelected,
  responseVehicle,
  verifyFitmentProductData,
  discardVehicle: discardVehicleProp,
  onChangeVehicle,
  openDialog,
}: Props) {
  const discardVehicle = () => {
    discardVehicleProp();

    onChangeVehicle();
    fitmentSearchConfig.onVehicleDiscarded();
  };

  const getVehicleUrl = (pathname: string) =>
    getUriFromRequest({ selection: responseVehicle.selection }, { pathname, goToSearchPage: !pathname });

  const params = {
    template: templateName,
    isVehicleSelected,
    fitmentSearchTitle: fitmentSearchConfig.fitmentSearchTitle,
    fits: verifyFitmentProductData.fitsTheVehicle,
    productData: verifyFitmentProductData,
    vehicle: responseVehicle.filteredFieldMap,
    vehicleString: responseVehicle.toString(),
    vehicleValues: responseVehicle.serializable,
    changeVehicle: onChangeVehicle,
    discardVehicle,
    getVehicleUrl,
    openDialog,
  };
  const component = template.call(params);

  return cloneSafe(component, rootRef, { appendedClasses });
}

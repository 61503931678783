import { useRef, useEffect, useState, useCallback, type FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { TemplateFunction } from 'Components/types.ts';
import { selectedVehicleSelector } from 'Core/selectors/fitmentSearch/index.js';
import { Vehicle } from 'Models/index.ts';
import { vehicleColorUpdated } from 'Core/actions/fitmentSearch';
import { selectedVehicleColorIdSelector } from 'Core/reducers/fitmentSearch/storage.ts';

export interface Props {
  template: TemplateFunction<Params>;
  apiKey: string;
  config?: AutoSyncConfig;
  wheelMpn?: string;
  classModificator?: string;
  extraParams?: ExtraParams;
}

export type Params = {
  visualization: JSX.Element;
  isVehicleError: boolean;
  isWheelError: boolean;
} & ExtraParams;

type ExtraParams = Record<string, unknown>;

export interface AutoSyncConfig {
  el?: HTMLElement | null;
  height?: string;
  vehicleImageSize?: number;
  vehicleAngles?: number[];
  showColorPicker?: boolean;
  widthAdjustment?: string;
}

interface Color {
  Code: string;
  Id: number;
  Img001: boolean;
  Img014: boolean;
  Img032: boolean;
  Name: string;
  Rgb1: string;
  Rgb2: string;
  ShortName: string;
  Swatch: string;
}

interface AutoSyncVisualizer {
  loadVehicleByDescription: (vehicleDesc: string, options?: Record<string, unknown>) => Promise<void>;
  loadWheelByPn: (wheelMpn: string) => Promise<void>;
  setHeight: (height: string) => void;
  setVehicleColor: (colorId: number) => void;
  onVehicleColorChange: (callback: (color: Color) => void) => void;
}

const visualizerVehicleFields = ['Year', 'Make', 'Model', 'Submodel'];

// docs: https://vvs.autosyncstudio.com/express/docs/reference/classes/VvsExpress.VvsExpress.html

const AutoSyncVisualization: FC<Props> = ({
  template,
  apiKey,
  config,
  wheelMpn,
  classModificator,
  extraParams,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const visualizerRef = useRef<AutoSyncVisualizer>();

  const dispatch = useDispatch();

  const [isVehicleError, setIsVehicleError] = useState(false);
  const [isWheelError, setIsWheelError] = useState(false);
  const [isVisualizationHidden, setIsVisualizationHidden] = useState(false);

  const selectedVehicle = useSelector(selectedVehicleSelector);
  const colorId = useSelector(selectedVehicleColorIdSelector);

  const visualizeVehicle = useCallback(() => {
    if (!apiKey) {
      console.error('No api key found');
      return;
    }

    if (!visualizerRef.current) {
      visualizerRef.current = globalThis.initVvsExpress?.({
        apiUrl: 'https://api.autosyncstudio.com/',
        apiKey,
        el: containerRef.current,
        ...config,
      });
    }

    if (!visualizerRef.current) {
      return;
    }

    const visualizedClassName = classModificator
      ? `cm_vehicle-visualization__${classModificator}__vehicle-visualized`
      : null;

    if (visualizedClassName) {
      visualizedClassName && window.document.body.classList.remove(visualizedClassName);
    }

    const options: Record<string, unknown> = {};
    if (colorId) {
      options.colorId = +colorId;
    }

    tryVisualizeVehicle(visualizerRef, options, selectedVehicle)
      .then(() => {
        setIsVehicleError(false);
        setIsVisualizationHidden(false);

        visualizerRef.current?.onVehicleColorChange((color: Color) => {
          if (color.Name && color.Id) {
            dispatch(
              vehicleColorUpdated({
                vehicle: selectedVehicle,
                vehicleExtra: {
                  colorId: color.Id.toString(),
                },
              }),
            );
          }
        });

        if (visualizedClassName) {
          window.document.body.classList.add(visualizedClassName);
        }
      })
      .catch((err: Error) => {
        setIsVehicleError(true);
        setIsVisualizationHidden(true);
        if (err.message === 'vehicleString is empty') {
          return;
        }
        console.warn(err.message);
      });

    if (wheelMpn) {
      visualizerRef.current
        .loadWheelByPn(wheelMpn)
        .then(() => {
          setIsWheelError(false);
        })
        .catch((err: Error) => {
          setIsWheelError(true);
          console.warn(`Failed to visualize the '${wheelMpn}' wheel. ${err.message}`);
        });
    }
  }, [apiKey, config, wheelMpn, classModificator, dispatch, colorId, selectedVehicle]);

  useEffect(() => {
    try {
      visualizeVehicle();
    } catch (error) {
      console.error('Failed to visualize a vehicle', error);
    }
  }, [visualizeVehicle]);

  useEffect(() => {
    const height = isVisualizationHidden ? '0px' : (config?.height ?? '150px');
    try {
      visualizerRef.current?.setHeight(height);
    } catch (error) {
      console.error('Failed to set height', error);
    }
  }, [isVisualizationHidden, config?.height]);

  const visualization = (
    <div
      ref={containerRef}
      className={[
        'cm_vehicle-visualization',
        classModificator && `cm_vehicle-visualization__${classModificator}`,
        isVehicleError && 'cm_hide',
      ]
        .filter(Boolean)
        .join(' ')}
    ></div>
  );

  return template.call({ visualization, isVehicleError, isWheelError, ...extraParams });
};

async function tryVisualizeVehicle(
  visualizerRef: React.MutableRefObject<AutoSyncVisualizer | undefined>,
  options: Record<string, unknown>,
  selectedVehicle: Vehicle,
) {
  const vehicleString = new Vehicle(
    selectedVehicle.filter((v) => visualizerVehicleFields.includes(v.field)),
    visualizerVehicleFields,
  ).toString();

  if (!vehicleString) {
    throw new Error('vehicleString is empty');
  }

  try {
    await visualizerRef.current?.loadVehicleByDescription(vehicleString, options);
  } catch {
    console.warn(
      `Failed to visualize the '${vehicleString}' vehicle. Trying to find a vehicle without specifying a Submodel`,
    );

    const fieldsWithoutSubmodel = visualizerVehicleFields.slice(0, -1);

    const vehicleStringWithoutSubmodel = new Vehicle(
      selectedVehicle.filter((v) => fieldsWithoutSubmodel.includes(v.field)),
      fieldsWithoutSubmodel,
    ).toString();

    try {
      await visualizerRef.current?.loadVehicleByDescription(vehicleStringWithoutSubmodel, options);
    } catch (error) {
      throw new Error(`Failed to visualize the '${vehicleString}' vehicle. ${error.message}`);
    }
  }
}

export default AutoSyncVisualization;

import requestConfig from 'Models/uiConfig/requestConfig.js';

export const isolatedHeader = 'isolatedHeader' as const;
export const isolatedDialog = 'isolatedDialog' as const;
export type IsolatedKey = typeof isolatedHeader | typeof isolatedDialog | null;

export function getIsolatedKeyFromWidgetName(name: string): IsolatedKey {
  const [nameRoot] = name.split('_');
  switch (nameRoot) {
    case 'DialogVehicleWidget':
    case 'GarageVehicleWidget':
      return isolatedDialog;
    case 'HomeVehicleWidget':
      return isolatedHeader;
    case 'HeaderVehicleWidget':
    case 'FacetTilesVehicleWidget':
      return !requestConfig.hasSearchResults ? isolatedHeader : null;
    default:
      return null;
  }
}

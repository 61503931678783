import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { replaceValue } from 'Core/actions/request.js';
import { FacetValue } from 'Models/index.ts';
import { cloneSafe } from 'Utils/components.ts';
import { getRangeFromTerm } from 'Utils/ranged.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import { RangedInputs, SimpleValue } from '../common/index.ts';

import type {
  RepeaterFunctionInvoker,
  TemplateFunction,
  TemplateFunctionInvoker,
  TemplateResult,
} from 'Components/types.ts';
import type { MinMax } from 'Utils/ranged.ts';
import type { FacetCommonProps, CommonParams } from '../baseFacet.js';
import type { Params as RangedInputsParams } from '../common/rangedInputs.js';

type Params = Omit<CommonParams, 'selection'> & {
  Inputs: TemplateFunctionInvoker<RangedInputsParams>;
  Values: RepeaterFunctionInvoker<unknown>;
};

type Props = Omit<FacetCommonProps, 'templateFunc'> & {
  templateFunc: TemplateFunction<Params>;
};

const RangedListFacet: FC<Props> = ({
  facet: { facetedValues, isSingleValue, selection },
  field,
  config: { step = '1' },
  templateFunc,
  facetRef,
  commonParams,
  commonRoles,
}) => {
  const dispatch = useDispatch();

  if (!facetedValues.length) {
    return null;
  }

  const selectedTerms: MinMax = selection.length === 1 ? getRangeFromTerm(selection[0].term) : ['*', '*'];

  function handleCustomRange([minValue, maxValue]: MinMax) {
    const term = FacetValue.createRangedTerm(minValue, maxValue);
    const isSelected = !(minValue === '*' && maxValue === '*');
    dispatch(replaceValue({ term, field, isUnique: true, isSelected }, { mayDiscardValue: true }));
  }

  const Inputs = (templ: TemplateFunction<RangedInputsParams>) =>
    (
      <RangedInputs
        template={templ}
        selectedValues={selectedTerms}
        handleCustomRange={handleCustomRange}
        key={`inputs-for-${field}`}
        step={step}
      />
    ) as TemplateResult;

  const Values = facetedValues.map((value) => (templ) => {
    const props = {
      value,
      isSingleValue,
      template: templ,
    };
    return <SimpleValue {...props} key={FacetValue.termKey(value)} />;
  }) as RepeaterFunctionInvoker<unknown>;

  const onClick = simpleHandler({ ...commonRoles });

  const component = templateFunc.call({ ...commonParams, Inputs, Values });
  return cloneSafe(component, facetRef, { onClick });
};

export default RangedListFacet;

import { scrollToBase } from 'Core/actions/show.ts';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import scheduleIdleCallback from 'Modules/scheduleIdleCallback.js';
import { animateLinear } from 'Utils/animatePromise.js';
import { epicFromHandlers } from './common.js';

const widgets = new Map(
  uiConfig.widgetsConsideredInScroll.filter((w) => w.element.offsetParent !== null).map((w) => [w, 0]),
);
const scrollDuration = 100;
const possibleDelay = 100;

export default epicFromHandlers({
  [scrollToBase.type]({ state }) {
    if (state.show.infiniteScrollLoading) {
      return;
    }
    scheduleIdleCallback(
      () => {
        [...widgets.keys()].forEach((w) => widgets.set(w, getOffset(w)));

        const base = Math.min(...widgets.values()) - getScrollOffset();
        const { pageYOffset: current } = window;
        const final = Math.min(base, current);

        return animateLinear(current, final, (y) => window.scroll(0, y), scrollDuration);
      },
      { timeout: possibleDelay },
    );
  },
});

function getOffset({ element }) {
  let offset = 0;
  let current = element;
  while (current) {
    offset += current.offsetTop;
    current = current.offsetParent;
  }
  return offset;
}

function getScrollOffset() {
  const stickyHeader = window.document.querySelector(uiConfig.stickyHeaderSelector);
  if (stickyHeader) {
    let current = stickyHeader;
    while (current) {
      const { position } = window.getComputedStyle(current);
      if (position === 'fixed' || position === 'sticky') {
        return stickyHeader.offsetHeight;
      }
      current = current.offsetParent;
    }
  }
  return 0;
}

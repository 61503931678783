import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sendVehicleDialogRequest, removalFromGarageRequested } from 'Core/actions/fitmentSearch/index.js';
import { useDropdown } from 'Core/hooks/index.js';
import {
  garageEmptySelector,
  garageSizeSelector,
  isVehicleSelectedSelector,
  selectedVehicleSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { cloneSafe } from 'Utils/components.ts';
import GarageContext from './garageContext.ts';
import Dropdown from './dropdown/index.ts';

export const vehicleWidgetName = 'GarageVehicleWidget';

const dropdownName = 'garageDropdown';

export default function Garage({
  template,
  name,
  doNotRedirectOnVehicleSelect,
  isAutoVehicleSelectionDisabled = true,
  redirectUrl,
  useNativeDropdown,
  useVehicleWidget,
}) {
  const dispatch = useDispatch();
  const rootRef = useRef();
  const [isShowVehicleDropdowns, setIsShowVehicleDropdowns] = useState(false);
  const {
    hideDropdown: closeGarage,
    showDropdown: openGarage,
    DO_NOT_USE_visible: isDropdownVisible,
  } = useDropdown(rootRef, '.cm_garage', {
    dropdownName,
    toggle: true,
  });
  const size = useSelector(garageSizeSelector);
  const vehicle = useSelector(selectedVehicleSelector);
  const isVehicleSelected = useSelector(isVehicleSelectedSelector);
  const isEmpty = useSelector(garageEmptySelector);
  const vehicleString = vehicle.toString();

  useEffect(() => {
    if (!isDropdownVisible) {
      setIsShowVehicleDropdowns(false);
    } else if (isDropdownVisible && (useVehicleWidget || isShowVehicleDropdowns)) {
      dispatch(sendVehicleDialogRequest());
    }
  }, [dispatch, isDropdownVisible, isShowVehicleDropdowns, useVehicleWidget]);

  const dropdown = (templ) => <Dropdown template={templ} key="dropdown" />;

  const showVehicleDropdowns = () => setIsShowVehicleDropdowns(true);

  const removeSelectedVehicle = () => {
    dispatch(removalFromGarageRequested(vehicle, true));
    fitmentSearchConfig.onVehicleDiscarded();
  };

  const component = template.call({
    name,
    size,
    [dropdownName]: dropdown,
    isDropdownVisible,
    isEmpty,
    isVehicleSelected,
    vehicleString,
    vehicle: vehicle.filteredFieldMap,
    vehicleValues: vehicle.serializable,
    showVehicleDropdowns,
    removeSelectedVehicle,
    openGarage,
    closeGarage,
  });

  return (
    <GarageContext.Provider
      value={{
        closeGarage,
        doNotRedirectOnVehicleSelect,
        isAutoVehicleSelectionDisabled,
        isShowVehicleDropdowns,
        redirectUrl,
        showVehicleDropdowns,
        useNativeDropdown,
        useVehicleWidget,
        isVehicleSelected,
      }}
    >
      {cloneSafe(component, rootRef)}
    </GarageContext.Provider>
  );
}

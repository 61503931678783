import pageType from 'Addons/search/pageType.ts';
import uiConfig from './uiConfig.js';

if (!window.ShopifyAnalytics) {
  console.warn('window.ShopifyAnalytics not found');
}

const {
  ShopifyAnalytics: {
    meta: { page: { pageType: shopifyPageType, resourceId: shopifyResourceId } = {} } = {},
  } = {},
} = window;

const cultureCode = window.Shopify?.routes?.root.slice(0, -1) ?? '';

// 4: https://store.myshopify.com/en/collections/maintenance-tools/tag
// 3: https://store.myshopify.com/collections/maintenance-tools/tag
const referrerPosition = cultureCode ? 4 : 3;
const isReferrerInUrl =
  shopifyPageType === 'collection' &&
  window.location.pathname.split('/').filter(Boolean).length >= referrerPosition;

const urlQueryParam = new URLSearchParams(window.location.search).get('q');

const pageTypeToPreselectionMap = {
  category: shopifyResourceId ? [{ field: 'collection_ids', term: `${shopifyResourceId}` }] : [],
  brand: urlQueryParam ? [{ field: 'vendor', term: urlQueryParam }] : [],
};

function getLocalPreselection(pageType) {
  return pageTypeToPreselectionMap[pageType] || null;
}

function facetResponseHandler(response) {
  if (uiConfig.page.getPageType() === pageType.category && response.Facets && shopifyResourceId) {
    const categoryFacet = response.Facets.find((facet) => facet.FieldName === 'category');

    if (categoryFacet) {
      const categoryPreselection = categoryFacet.Selection?.find(
        (facet) => facet.Payload === `${shopifyResourceId}`,
      )?.Term;

      if (categoryPreselection) {
        categoryFacet.TreeLevel = categoryPreselection.split('>').length - 1;
      }
    }
  }

  return response;
}

function responseHandler(response) {
  return facetResponseHandler(response);
}

function collectExtra() {
  const extra = {};
  const { country, currency, locale } = window.Shopify;

  if (country) {
    extra.country = country.toLowerCase();
  }

  const activeCurrency = currency?.active;
  if (activeCurrency) {
    extra.currency = activeCurrency.toLowerCase();
  }
  const currencyRate = currency?.rate;
  if (currencyRate) {
    extra.currencyRate = currencyRate;
  }

  if (locale) {
    extra.locale = locale;
  }

  if (cultureCode) {
    extra.culturecode = cultureCode;
  }

  if (shopifyPageType === 'page') {
    extra.pageId = shopifyResourceId;
  }

  return extra;
}

function getSearchRequestDefaults(pageType) {
  const requestDefaults = {};

  if (isReferrerInUrl) {
    requestDefaults.referrer = window.location.pathname;
  }

  requestDefaults.extra = collectExtra();

  return requestDefaults;
}

function getAutocompleteRequestDefaults() {
  return collectExtra();
}

getLocalPreselection.defaults = { getter: getLocalPreselection };

async function getCustomerAsync() {
  const id =
    window.ShopifyAnalytics?.meta?.page?.customerId || window.meta?.page?.customerId || window.__st?.cid;
  window.Convermax.customerId = id;
  window.Convermax.isLoggedIn = !!id;
  return id ? { id } : null;
}

export default {
  getLocalPreselection,
  getSearchRequestDefaults,
  responseHandler,
  facetResponseHandler,
  getAutocompleteRequestDefaults,
  getCustomerAsync,
  isReferrerInUrl,
};

import { createAction } from '@reduxjs/toolkit';

import type { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';
import type { FacetValueBase } from 'Models/index.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';

type SetResponseMeta = {
  appendItems?: boolean;
  isAutoVehicleSelectionDisabled?: boolean;
  isolatedKey?: IsolatedKey;
  isPartialMode?: boolean;
  onInit?: true;
  preselection?: FacetValueBase[];
  sendIsolatedRequest?: boolean;
  doNotRedirectOnVehicleSelect?: boolean;
  redirectUrl?: string;
  vehicleWidgetName?: string;
};
export const setResponse = createAction(
  'SET_RESPONSE',
  (payload: ServerModel.SearchResponse, meta: SetResponseMeta = {}) => ({
    payload,
    meta: { isolatedKey: null, preselection: [], ...meta },
  }),
);

import { useCallback, type FC } from 'react';
import { useSelector } from 'react-redux';

import { useResizeState } from 'Core/hooks';
import { fitmentTableResponseSelector } from 'Core/selectors/fitmentSearch/index.js';
import { FitmentField } from 'Modules/serverApi/types.ts';
import { cloneSafe } from 'Utils/components';
import VehicleSelector, { type Params as VehicleSelectorParams } from './vehicleSelector';

import type {
  RepeaterFunctionInvoker,
  TemplateFunction,
  TemplateFunctionInvoker,
  TemplateResult,
} from 'Components/types.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';

enum layoutType {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

type FitmentFieldType = {
  vehicleSpecific: boolean;
  noFitmentData: boolean;
  universalFit: boolean;
  manyFitments: boolean;
};

interface ItemParams {
  fields: string[];
  isTitle?: true;
  vehicleSelector?: TemplateFunctionInvoker<VehicleSelectorParams> | null;
}

interface Params {
  items: RepeaterFunctionInvoker<ItemParams>;
  template: keyof typeof layoutType;
}

interface Props {
  template: TemplateFunction<Params | FitmentFieldType>;
  columnBreakpoint?: number;
}

const FitmentTable: FC<Props> = ({ template, columnBreakpoint = 425 }) => {
  const layout = useResizeState(
    useCallback(
      () => (window.innerWidth > columnBreakpoint ? layoutType.horizontal : layoutType.vertical),
      [columnBreakpoint],
    ),
  ) as keyof typeof layoutType;

  const response = useSelector(fitmentTableResponseSelector) as ServerModel.FitmentsResponseType;

  if (
    !response ||
    (response.Fitment === FitmentField.vehicleSpecific &&
      (!response.Fitments?.length || !response.Fields?.length))
  ) {
    return null;
  }

  const { Fields, Fitment, Fitments } = response;

  const fitmentFieldData = {
    vehicleSpecific: Fitment === FitmentField.vehicleSpecific,
    noFitmentData: Fitment === FitmentField.noFitmentData,
    universalFit: Fitment === FitmentField.universalFit,
    manyFitments: Fitment === FitmentField.manyFitments,
  };

  if (!Fields?.length || !Fitments?.length) {
    return template.call({ ...fitmentFieldData });
  }

  const vehicleSelectors = Fitments.some(
    (f) => !!f.Year && typeof f.Year === 'object' && !Array.isArray(f.Year),
  )
    ? (Fitments.map(
        (fitment) => (templ) =>
          (
            <VehicleSelector template={templ} years={fitment.Year as Record<string, string>} />
          ) as TemplateResult,
      ) as TemplateFunctionInvoker<VehicleSelectorParams>[] | undefined)
    : null;

  const tableTitleData = Object.fromEntries(Fields.map((field) => [field, field]));

  const items = [
    (templ) =>
      cloneSafe(templ.call({ ...tableTitleData, fields: Fields, isTitle: true }), null, { key: 'title' }),
    ...Fitments.map((row, i: number) => (templ: TemplateFunction<ItemParams>) => {
      const key = Object.entries(row)
        .map((pair) => `${pair[0]}|${pair[1]}`)
        .join('&');
      const component = templ.call({
        ...row,
        fields: Fields,
        vehicleSelector: vehicleSelectors ? vehicleSelectors[i] : null,
      });
      return cloneSafe(component, null, { key });
    }),
  ] as RepeaterFunctionInvoker<ItemParams>;

  return template.call({ items, template: layout, ...fitmentFieldData });
};

export default FitmentTable;

import { useEffect, useRef } from 'react';

import { useCollapsible } from 'Core/hooks';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig';
import { LayoutType } from './useLayoutState.tsx';

import type { RefObject } from 'react';

export function useCollapsing(
  rootRef: RefObject<HTMLElement | undefined>,
  isVehicleSelected: boolean,
  layoutState: LayoutType,
  initCollapsed: boolean,
  aboutToChange: boolean,
) {
  const disableCollapse = layoutState !== LayoutType.column;
  const [collapsed, toggleCollapsed] = useCollapsible(
    rootRef,
    null,
    aboutToChange ? false : isVehicleSelected || initCollapsed,
    {
      disableCollapse,
    },
  );

  const wasVehicleSelectedRef = useRef(isVehicleSelected);
  useEffect(
    function collapseDropdownsWhenVehicleIsSelected() {
      if (
        !wasVehicleSelectedRef.current &&
        !fitmentSearchConfig.isAutoRedirectDisabled &&
        isVehicleSelected &&
        !collapsed
      ) {
        toggleCollapsed();
      }
      wasVehicleSelectedRef.current = isVehicleSelected;
    },
    [collapsed, isVehicleSelected, toggleCollapsed, wasVehicleSelectedRef],
  );

  return { collapsed, toggleCollapsed, withCollapsing: !disableCollapse };
}

import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';

export default {
  onVehicleSelected,
  onVehicleDiscarded,
  onVerifyFitmentInitResponseReceived,
  onVerifyFitmentResponseReceived,
};

function onVehicleSelected(vehicle) {
  setVehicleAtShopifyCart(vehicle);
}

function onVehicleDiscarded() {
  const emptyVehicle = window?.Convermax.templates.config.fitmentSearchConfig.fields.reduce(
    (acc, cur) => ({ ...acc, [cur]: '' }),
    {},
  );

  setVehicleAtShopifyCart(emptyVehicle);
}

function onVerifyFitmentInitResponseReceived(response) {
  setNotesOnCartItems(response);
}

function onVerifyFitmentResponseReceived(response) {
  setNotesOnCartItems(response);
}

function setVehicleAtShopifyCart(vehicle) {
  const vehicleString = fitmentSearchConfig.fields
    .map((field) => vehicle[field])
    .filter(Boolean)
    .join(' ');

  fetch(`${window?.Shopify.routes.root}cart/update.js`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ attributes: { ...vehicle, Vehicle: vehicleString } }),
  }).then((response) => {
    if (!response.ok) {
      console.error(response.json());
    }
  });
}

function setNotesOnCartItems({ Facets, Items }) {
  const forms = window.document.querySelectorAll('form[action^="/cart/add"');

  if (!window?.Convermax.templates.config.fitmentSearchConfig.hideVerifyOnCartItems) {
    const fits = Items[0]?.fitsTheVehicle;

    if (forms && fits) {
      const vehicleString = fitmentSearchConfig.fields
        .map((field) => Facets.find((f) => f.FieldName === field)?.Selection[0]?.Value)
        .filter(Boolean)
        .join(' ');

      if (vehicleString) {
        forms.forEach((form) => {
          const vehicleInput = form.querySelector('.cm_verify-message-property');

          if (vehicleInput) {
            vehicleInput.value = getVerifyFitmentText(fits, vehicleString);
          } else {
            const input = document.createElement('input');
            input.className = 'cm_verify-message-property';
            input.type = 'hidden';
            input.name = 'properties[Fitment]';
            input.value = getVerifyFitmentText(fits, vehicleString);

            // <input class="cm_verify-message-property" type="hidden" name="properties[Verify Message]" value="This product fit your 2024 Ford F-150">
            form.appendChild(input);
          }
        });
      }
    }
  }

  const vehicleInfo = Items[0]?.vehicle_info;

  if (vehicleInfo) {
    forms.forEach((form) => {
      const vehicleInput = form.querySelector('.cm_vehicle-info-message-property');

      if (vehicleInput) {
        vehicleInput.value = vehicleInfo;
      } else {
        const input = document.createElement('input');
        input.className = 'cm_vehicle-info-message-property';
        input.type = 'hidden';
        input.name = 'properties[Vehicle Info]';
        input.value = vehicleInfo;

        // <input class="cm_vehicle-info-message-property" type="hidden" name="properties[Vehicle Info]" value="FrontAxle Diameter: 17 to 20, FrontAxle Width: 6.5 to 9, Offset: 35 to 55 ...">
        form.appendChild(input);
      }
    });
  }
}

function getVerifyFitmentText(fits, vehicleString) {
  switch (fits) {
    case 'yes':
      return `${uiConfig.replacements?.VERIFY_FITMENT_FITS || 'This product fits your'} ${vehicleString}`;
    case 'no':
      return `${uiConfig.replacements?.VERIFY_FITMENT_NOT_FIT || 'This product DOES NOT fit your'} ${vehicleString}`;
    case 'aggressive':
      return `${uiConfig.replacements?.VERIFY_FITMENT_AGGRESSIVE_FITS || 'Vehicle modifications may be required to fit your'} ${vehicleString}`;
    case 'universal':
      return 'Universal';
  }
}

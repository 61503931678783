import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isolatedDialog } from 'Addons/fitmentSearch/isolatedKeys';
import {
  getClassesByLayoutState,
  useLayoutState,
} from 'Components/fitmentSearch/vehicleWidget/useLayoutState';
import { createSelectRepeater, SelectContextProvider } from 'Components/smallComponents/select.tsx';
import { isolatedDiscardVehicle, replaceVehicleValue, vehicleSelected } from 'Core/actions/fitmentSearch';
import { usePopup } from 'Core/hooks/index.js';
import {
  createFitmentSearchResponseFacetsSelector,
  fitmentSearchBaseFieldsSelector,
  fitmentSearchFieldsSelector,
  isSpecifyDialogOpenedSelector,
  isVehicleSelectedSelector,
  isVehicleSelectionForcedSelector,
  selectedVehicleSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { Vehicle } from 'Models';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig';
import { cloneSafe } from 'Utils/components';

import type { FC } from 'react';
import type { Params as SelectParams } from 'Components/smallComponents/select.tsx';
import type {
  RepeaterFunctionInvoker,
  TemplateFunction,
  TemplateFunctionInvoker,
  TemplateResult,
} from 'Components/types.ts';
import type { FacetCollection } from 'Models';
import type { VehicleValue } from 'Models/vehicle';

const dialogName = 'VehicleWidgetDialog';
const isolatedKey = isolatedDialog;

type Params = {
  name: string;
  close: () => void;
  vehicleWidget: TemplateFunctionInvoker<VehicleWidgetParams>;
};
type VehicleWidgetParams = {
  name: string;
  selects: RepeaterFunctionInvoker<SelectParams>;
  allowToDiscardVehicle: boolean;
  allowToSetVehicle: boolean;
  isSpecifyDialogOpened: boolean;
  isVehicleSelected: boolean;
  vehicle: Record<string, string>;
  discardVehicle: () => void;
  selectVehicle: () => void;
};

interface Props {
  template: TemplateFunction<Params>;
  columnBreakpoint?: number;
  doNotRedirectOnVehicleSelect?: boolean;
  isAutoVehicleSelectionDisabled?: boolean;
  isAlwaysColumnLayout?: boolean;
  fields?: string[];
  globalVehicleDiscardEnabled?: boolean;
  redirectUrl?: string;
  useNativeDropdown?: boolean;
  useSearchableDropdown?: boolean;
}

const VehicleWidgetDialog: FC<Props> = ({
  template,
  columnBreakpoint,
  doNotRedirectOnVehicleSelect,
  isAutoVehicleSelectionDisabled,
  isAlwaysColumnLayout,
  fields,
  globalVehicleDiscardEnabled,
  redirectUrl,
  useNativeDropdown,
  useSearchableDropdown,
}) => {
  const rootRef = useRef<HTMLElement>(null);
  const dispatch = useDispatch();

  const { opened, close } = usePopup(dialogName);

  const isVehicleSelected = useSelector(isVehicleSelectedSelector);
  const selectedVehicle = useSelector(selectedVehicleSelector);

  const responseFacets = useSelector(
    createFitmentSearchResponseFacetsSelector(isolatedKey),
  ) as FacetCollection;
  const facets = fields ? responseFacets.filter((facet) => fields.includes(facet.field)) : responseFacets;

  const fitmentFields = useSelector(fitmentSearchFieldsSelector);
  const baseFields = useSelector(fitmentSearchBaseFieldsSelector);
  const requiredFacets = fields ? facets : facets.filter((facet) => baseFields.includes(facet.field));
  const isAllRequiredFieldSelected = requiredFacets.filter((f) => !f.selection.length).length === 0;

  const isSpecifyDialogOpened = useSelector(isSpecifyDialogOpenedSelector);
  const isVehicleSelectionForced = useSelector(isVehicleSelectionForcedSelector);

  const layoutState = useLayoutState(rootRef, facets, columnBreakpoint, isAlwaysColumnLayout);
  const appendedClasses = [...getClassesByLayoutState(layoutState)];

  if (!opened) {
    return null;
  }

  // if a user select a vehicle without some extra fields manually by the button,
  // mark those fields as ignored not to show the specify dialog for them
  const responseVehicle = new Vehicle(
    fitmentFields.map(
      (field) =>
        facets.selection.find((v) => v.field === field) || { field, term: '__ignored', value: '__ignored' },
    ) as VehicleValue[],
    fitmentFields,
  );

  const vehicleWidget: TemplateFunctionInvoker<VehicleWidgetParams> = (templ) => {
    const selectVehicle = () => {
      if (isAllRequiredFieldSelected) {
        dispatch(
          vehicleSelected(responseVehicle, {
            doNotRedirectOnVehicleSelect,
            isSpecifyDialogOpened,
            isVehicleSelectionForced,
            isolatedKey,
            redirectUrl,
            sendIsolatedRequest: isolatedKey !== null,
            vehicleWidgetName: dialogName,
          }),
        );

        fitmentSearchConfig.onVehicleSelected(responseVehicle.filteredFieldMap);
      }
    };

    const discardVehicle = () => {
      dispatch(isolatedDiscardVehicle(isolatedKey, globalVehicleDiscardEnabled));
      if (isAllRequiredFieldSelected) {
        fitmentSearchConfig.onVehicleDiscarded();
      }
    };

    const selects =
      facets.length > 0
        ? createSelectRepeater(
            facets.map(({ field, name, facetedValues }, index) => ({
              entries: facetedValues.map((v) => ({
                value: v.value,
                term: v.term,
                payload: v.payload,
                selected: v.isSelected,
              })),
              extraClassName: 'cm_vehicle-widget_select',
              disabled: !facetedValues.length,
              hideNullOption: true,
              field,
              title: name,
              key: field,
              index,
              useNativeDropdown: !useSearchableDropdown && useNativeDropdown,
              isSearchable: useSearchableDropdown,
              onChange: (term) => {
                const facetValue = facetedValues.find((v) => v.term === term);
                if (facetValue) {
                  dispatch(
                    replaceVehicleValue(facetValue, isolatedKey, fitmentFields, {
                      isAutoVehicleSelectionDisabled:
                        isAutoVehicleSelectionDisabled ?? fitmentSearchConfig.isAutoVehicleSelectionDisabled,
                      isPartialMode: !!fields,
                      sendIsolatedRequest: isolatedKey !== null || isAutoVehicleSelectionDisabled,
                      doNotRedirectOnVehicleSelect,
                      redirectUrl,
                      vehicleWidgetName: dialogName,
                    }),
                  );
                }
              },
            })),
          )
        : createSelectRepeater(
            baseFields.map((field: string, index: number) => ({
              entries: [{ value: '', term: '', selected: false }],
              extraClassName: 'cm_vehicle-widget_select',
              disabled: index !== 0,
              loading: index === 0,
              hideNullOption: true,
              field,
              title: field,
              key: field,
              index,
              useNativeDropdown: !useSearchableDropdown && useNativeDropdown,
              isSearchable: useSearchableDropdown,
              onChange: () => {
                /* stub */
              },
            })),
          );

    const component = templ.call({
      name: dialogName,
      selects,
      allowToDiscardVehicle: facets.selection.length > 0,
      allowToSetVehicle: isAllRequiredFieldSelected,
      isSpecifyDialogOpened,
      isVehicleSelected,
      vehicle: selectedVehicle.filteredFieldMap,
      discardVehicle,
      selectVehicle,
    });

    return (
      <SelectContextProvider>{cloneSafe(component, rootRef, { appendedClasses })}</SelectContextProvider>
    ) as TemplateResult;
  };

  const component = template.call({
    name: dialogName,
    close,
    vehicleWidget,
  });

  return cloneSafe(component, rootRef, { appendedClasses });
};

export default VehicleWidgetDialog;

import { useDispatch, useSelector } from 'react-redux';

import pageType from 'Addons/search/pageType.ts';
import FacetToggle from 'Components/smallComponents/facetToggle.tsx';
import { createSelectRepeater, SelectContextProvider } from 'Components/smallComponents/select.tsx';
import {
  isolatedDiscardVehicle,
  sendVerifyFitmentRequest,
  replaceVehicleValue,
  vehicleExtraFieldsDiscardingRequested,
  vehicleSelected,
} from 'Core/actions/fitmentSearch/index.js';
import {
  createFilteredFitmentSearchResponseFieldsSelector,
  createFitmentSearchResponseSelectionSelector,
  createRequiredFitmentSearchFieldsSelector,
  createAllRequiredFieldsHaveSelectionSelector,
  fitmentSearchBaseFieldsSelector,
  fitmentSearchFieldsSelector,
  isSpecifyDialogOpenedSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { FacetValueFull, Vehicle } from 'Models/index.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { cloneSafe } from 'Utils/components.ts';

import type { TemplateFunction, TemplateResult } from 'Components/types.ts';
import type {
  Props as FacetToggleProps,
  Params as FacetToggleParams,
} from 'Components/smallComponents/facetToggle.tsx';
import type { VehicleValue } from 'Models/vehicle.ts';
import type { InnerProps as Props } from './vehicleDropdowns.tsx';

export default function ActualDropdowns({
  template,
  name,
  aboutToChange,
  appendedClasses,
  collapsing,
  facets,
  fields,
  isAutoVehicleSelectionDisabled,
  isolatedKey,
  isVehicleSelected,
  facetToggleConfig,
  onSelectVehicle,
  onChangeVehicle,
  openDialog,
  rootRef,
  useNativeDropdown,
  globalVehicleDiscardEnabled,
  redirectUrl,
  doNotRedirectOnVehicleSelect,
  isVehicleSelectionForced,
  useSearchableDropdown,
}: Props) {
  const dispatch = useDispatch();

  const responseFields = useSelector(
    createFilteredFitmentSearchResponseFieldsSelector(isolatedKey),
  ) as string[];
  const requiredFields = useSelector(createRequiredFitmentSearchFieldsSelector(isolatedKey)) as
    | string[]
    | null;
  const selection = useSelector(
    createFitmentSearchResponseSelectionSelector(isolatedKey),
  ) as FacetValueFull[];
  const isAllRequiredFieldHasSelection = useSelector(
    createAllRequiredFieldsHaveSelectionSelector(isolatedKey),
  ) as boolean;
  const fitmentFields = useSelector(fitmentSearchFieldsSelector);
  const fitmentBaseFields = useSelector(fitmentSearchBaseFieldsSelector);
  const isSpecifyDialogOpened = useSelector(isSpecifyDialogOpenedSelector);

  // if a user sets a vehicle without some optional fields, we want to set those fields as ignored
  // and not to show the dialog to specify them
  const vehicle = new Vehicle(
    responseFields
      .map(
        (field) =>
          selection.find((v) => v.field === field) ||
          (!requiredFields?.includes?.(field) && { field, term: '__ignored', value: '__ignored' }),
      )
      .filter(Boolean) as VehicleValue[],
    responseFields,
  );

  const selectVehicle = () => {
    if (isAllRequiredFieldHasSelection) {
      if (uiConfig.pageType === pageType.product && doNotRedirectOnVehicleSelect) {
        // vehicle will be selected inside
        dispatch(sendVerifyFitmentRequest(vehicle));
      } else {
        dispatch(
          vehicleSelected(vehicle, {
            doNotRedirectOnVehicleSelect,
            isSpecifyDialogOpened,
            isVehicleSelectionForced,
            isolatedKey,
            redirectUrl,
            sendIsolatedRequest: fitmentSearchConfig.isOnCategorySelectionPage,
            vehicleWidgetName: name,
          }),
        );
      }
      fitmentSearchConfig.onVehicleSelected(vehicle.filteredFieldMap);
      onSelectVehicle();

      if (!collapsing.collapsed) {
        collapsing.toggleCollapsed();
      }
    }
  };
  const discardVehicle = () => {
    dispatch(isolatedDiscardVehicle(isolatedKey, globalVehicleDiscardEnabled));
    if (isAllRequiredFieldHasSelection) {
      fitmentSearchConfig.onVehicleDiscarded();
    }
  };
  const discardExtraFields = () =>
    dispatch(vehicleExtraFieldsDiscardingRequested(isolatedKey, fitmentBaseFields, fitmentFields));

  const selects = createSelectRepeater(
    facets.map(({ field, name, facetedValues }, index) => ({
      entries: facetedValues.map((v) => ({
        value: v.value,
        term: v.term,
        payload: v.payload,
        selected: v.isSelected,
      })),
      extraClassName: 'cm_vehicle-widget_select',
      disabled: !facetedValues.length,
      hideNullOption: true,
      field,
      title: name,
      key: field,
      index,
      useNativeDropdown: !useSearchableDropdown && useNativeDropdown,
      isSearchable: useSearchableDropdown,
      onChange: (term) => {
        const facetValue = facetedValues.find((v) => v.term === term);
        if (facetValue) {
          dispatch(
            replaceVehicleValue(facetValue, isolatedKey, fitmentFields, {
              isAutoVehicleSelectionDisabled:
                isAutoVehicleSelectionDisabled ?? fitmentSearchConfig.isAutoVehicleSelectionDisabled,
              isPartialMode: !!fields,
              sendIsolatedRequest: fitmentSearchConfig.isOnCategorySelectionPage,
              doNotRedirectOnVehicleSelect,
              redirectUrl,
              vehicleWidgetName: name,
            }),
          );
        }
      },
    })),
  );

  const facetToggle = (templ: TemplateFunction<FacetToggleParams>) => {
    if (!facetToggleConfig) {
      return null;
    }

    const props = {
      ...facetToggleConfig,
      template: templ,
      key: 'facet-toggle',
    } as FacetToggleProps;

    return (<FacetToggle {...props} />) as TemplateResult;
  };

  const component = template.call({
    name,
    selects,
    vehicleString: vehicle.toString(),
    vehicleValues: vehicle.serializable,
    vehicle: vehicle.filteredFieldMap,
    allowToSetVehicle: isAllRequiredFieldHasSelection,
    allowToDiscardVehicle: !!selection.length,
    aboutToChange,
    discardVehicle,
    discardExtraFields,
    selectVehicle,
    openDialog,
    facetToggle,
    changeVehicle: onChangeVehicle,
    fitmentSearchTitle: fitmentSearchConfig.fitmentSearchTitle,
    template: 'active',
    isVehicleSelected,
    selection: Object.fromEntries(selection.map((select) => [select.field, select.value])),
    isVehicleSelectionForced,
    ...collapsing,
  });

  return <SelectContextProvider>{cloneSafe(component, rootRef, { appendedClasses })}</SelectContextProvider>;
}

import { useDispatch } from 'react-redux';

import { createSelectRepeater, SelectContextProvider } from 'Components/smallComponents/select.tsx';
import { replaceVerifyFitmentVehicleValue, vehicleSelected } from 'Core/actions/fitmentSearch/index.js';
import { cloneSafe } from 'Utils/components.ts';
import { FacetValue, Vehicle } from 'Models/index.ts';

import type { RefObject } from 'react';
import type { Params as SelectParams } from 'Components/smallComponents/select.tsx';
import type { RepeaterFunctionInvoker, TemplateFunction } from 'Components/types.ts';
import type { FacetCollection } from 'Models/index.ts';
import type { CloneSafeOptionsType } from 'Utils/components.ts';

export type Params = {
  template: string;
  fieldsToSpecify: string[];
  selects: RepeaterFunctionInvoker<SelectParams>;
  allowToSetVehicle: boolean;
  vehicleString: string;
  selectVehicle: () => void;
};

type Props = {
  template: TemplateFunction<Params>;
  rootRef: RefObject<HTMLElement>;
  appendedClasses: CloneSafeOptionsType['appendedClasses'];
  fitmentFields: string[];
  unselectedFacets: FacetCollection;
  vehicleString: string;
  onSelectVehicle: () => void;
};

export default function VerifyFitmentSpecifier({
  template,
  rootRef,
  appendedClasses,
  fitmentFields,
  unselectedFacets,
  vehicleString,
  onSelectVehicle,
}: Props) {
  const dispatch = useDispatch();

  const isAllFieldsSelected = !unselectedFacets.length;

  const selects = createSelectRepeater(
    unselectedFacets
      .filter((f) => f.facetedValues.length > 0)
      .map(({ field, name, facetedValues }) => ({
        entries: facetedValues.map((v) => ({
          value: v.value,
          term: v.term,
          payload: v.payload,
          selected: FacetValue.isInCollection(v, unselectedFacets.selection),
        })),
        field,
        extraClassName: 'cm_vehicle-widget_select',
        title: name,
        hideNullOption: true,
        key: field,
        onChange: (term) => {
          const facetValue = facetedValues.find((v) => v.term === term);
          if (facetValue) {
            dispatch(replaceVerifyFitmentVehicleValue(facetValue, { fitmentFields }));
          }
        },
      })),
  );

  const selectVehicle = () => {
    dispatch(
      vehicleSelected(new Vehicle(unselectedFacets.selection, fitmentFields), {
        isSelectedFromVerifyFitment: true,
        sendIsolatedRequest: true,
      }),
    );
    onSelectVehicle();
  };

  const component = template.call({
    template: 'inexact',
    fieldsToSpecify: unselectedFacets.map((f) => f.name),
    selects,
    allowToSetVehicle: isAllFieldsSelected,
    vehicleString,
    selectVehicle,
  });

  return <SelectContextProvider>{cloneSafe(component, rootRef, { appendedClasses })}</SelectContextProvider>;
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import SearchItem from 'Components/searchItem/index.jsx';
import useScroll from 'Core/hooks/horizontalScroll.ts';
import { createSelectedVehicleSelectionSelector } from 'Core/selectors/fitmentSearch/index.js';
import { productDataSelector } from 'Core/selectors/product.ts';
import { prepareResponseFacets } from 'Core/reducers/search/response.js';
import { SearchItemData, SearchItemDataCollection } from 'Models/index.ts';
import productConfig from 'Models/uiConfig/productConfig.js';
import { getUriFromRequest } from 'Modules/converter/index.js';
import { search } from 'Modules/serverApi/index.js';
import { cloneSafe } from 'Utils/components.ts';

import type { FC } from 'react';
import type { RepeaterFunctionInvoker, TemplateFunction } from 'Components/types.ts';
import type { UseScroll } from 'Core/hooks/horizontalScroll.ts';
import type { FacetRequest } from 'Models/index.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';

type Params = {
  widgetName: string;
  items: RepeaterFunctionInvoker<SearchItemDataCollection>;
  searchLink: string;
} & UseScroll;

type Props = {
  template: TemplateFunction<Params>;
  name: string;
  count?: number;
  requestExtra?: Record<string, unknown>;
  sort?: string;
  useManualItems?: true;
  getSelection?: (itemData: ServerModel.SearchItem) => FacetRequest[] | undefined;
  onItemsRendered?: () => void;
};

const RelatedItems: FC<Props> = ({
  template,
  name: widgetName,
  count,
  requestExtra,
  sort,
  useManualItems,
  getSelection,
  onItemsRendered,
}) => {
  const rootRef = useRef<HTMLElement>(null);

  const { scrollLeft, scrollRight, isLeftBorder, isRightBorder } = useScroll(rootRef);

  const { localItemId }: { localItemId?: string } = productConfig;

  const productData = useSelector(productDataSelector);

  const vehicleSelection = useSelector(createSelectedVehicleSelectionSelector);
  const colorsSelection = useMemo(
    () => (productData?._Color?.length ? productData._Color.toFacetValues('_Color') : []),
    [productData],
  );

  const [customRequestSelection, setCustomRequestSelection] = useState<FacetRequest[]>([]);
  const [relatedItems, setRelatedItems] = useState<SearchItemDataCollection>();

  const handleResponse = useCallback(
    (response: ServerModel.SearchResponse) => {
      setRelatedItems(
        new SearchItemDataCollection(
          response.Items.filter(
            (item) => `${SearchItemData.getFromRaw(item, 'id')}` !== `${localItemId}`,
          ).slice(0, count),
          'related',
        ),
      );
    },
    [count, localItemId],
  );

  useEffect(() => {
    // send a request without facet selection to make a response on the server, e.g. in plugins
    if (useManualItems && count) {
      search({
        selection: vehicleSelection,
        pageSize: count,
        extra: { itemId: localItemId, mode: 'RelatedItems', ...(requestExtra || {}) },
      }).then((response: ServerModel.SearchResponse) => {
        handleResponse(response);

        if (response.Facets.length) {
          setCustomRequestSelection(prepareResponseFacets(response.Facets, []).selection);
        }
      });
    }
  }, [count, localItemId, handleResponse, useManualItems, vehicleSelection, requestExtra]);

  useEffect(() => {
    if (productData && Object.keys(productData).length && getSelection && count) {
      let customSelection = getSelection(productData);

      if (!Array.isArray(customSelection)) {
        // don't let unknown things be added to the request
        customSelection = [];
      } else if (customSelection.length) {
        setCustomRequestSelection(customSelection);
      }

      const selection = [...customSelection, ...vehicleSelection, ...colorsSelection];

      search({
        selection,
        pageSize: count,
        sort,
        extra: { itemId: localItemId, ...(requestExtra || {}) },
      }).then((response: ServerModel.SearchResponse) => handleResponse(response));
    }
  }, [
    count,
    getSelection,
    localItemId,
    productData,
    handleResponse,
    sort,
    vehicleSelection,
    colorsSelection,
    requestExtra,
  ]);

  useEffect(() => {
    if (relatedItems?.length) {
      onItemsRendered?.();
    }
  }, [relatedItems, onItemsRendered]);

  if (!relatedItems?.length) {
    return null;
  }

  const { href: searchLink } = getUriFromRequest(
    {
      selection: [
        ...customRequestSelection,
        ...(useManualItems ? [] : vehicleSelection),
        ...colorsSelection,
      ].filter(Boolean),
    },
    { goToSearchPage: true },
  );

  const items = relatedItems
    ? (relatedItems.repeaterComponents(SearchItem) as RepeaterFunctionInvoker<SearchItemDataCollection>)
    : [];

  const component = template.call({
    widgetName,
    items,
    searchLink,
    scrollLeft,
    scrollRight,
    isLeftBorder,
    isRightBorder,
  });

  return cloneSafe(component, rootRef);
};

export default RelatedItems;

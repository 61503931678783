import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { toggle } from 'Core/actions/request.js';
import { cloneSafe } from 'Utils/components.ts';

export default function ToggleFacet({ facet, templateFunc, config, facetRef, commonParams }) {
  const dispatch = useDispatch();
  const [toggledByUser, setToggledByUser] = useState(false);

  const { term, initToggled, isInverted } = config;
  const { field, facetedValues, facetedSelection } = facet;

  const isTermSelected = !!facetedSelection.find((f) => f.term === term)?.isSelected;
  const isTermAvailable = facetedValues.some((f) => f.term === term);

  useEffect(() => {
    if (initToggled && !toggledByUser && !isTermSelected) {
      dispatch(toggle({ field, term }));
    }
  }, [dispatch, initToggled, toggledByUser, isTermSelected, field, term]);

  if (!isTermAvailable) {
    return null;
  }

  const toggleFacet = () => {
    setToggledByUser(true);
    dispatch(toggle({ field, term }));
  };

  const component = templateFunc.call({
    ...commonParams,
    isToggled: isInverted ? !isTermSelected : isTermSelected,
    toggleFacet,
  });

  return cloneSafe(component, facetRef);
}

import Vehicle from './vehicle.ts';

import type { RepeaterFunctionInvoker, TemplateResult } from 'Components/types.ts';
import type GarageItem from 'Components/fitmentSearch/garage/dropdown/garageItem.ts';
import type { Params as GarageItemParams } from 'Components/fitmentSearch/garage/dropdown/garageItem.ts';
import type { ArrayCallback, ArrayPredicate } from 'Core/types.ts';
import type { VehicleData } from './vehicle.ts';

export default class VehicleCollection {
  static empty = new VehicleCollection([]);

  _vehicles: Vehicle[];

  constructor(vehicles: Vehicle[]) {
    this._vehicles = vehicles;
  }

  get serializable(): VehicleData[] {
    return this._vehicles.map((v) => v.serializable);
  }

  get size(): number {
    return this._vehicles.length;
  }

  get last() {
    return this._vehicles[this._vehicles.length - 1];
  }

  concat(value: Vehicle | VehicleCollection | unknown): VehicleCollection {
    if (value instanceof VehicleCollection) {
      return new VehicleCollection(this._vehicles.concat(value._vehicles.filter((v) => !this.includes(v))));
    }
    if (value instanceof Vehicle) {
      return this.includes(value) ? this : new VehicleCollection(this._vehicles.concat(value));
    }
    console.error('Value to concat to garage was neither Vehicle instance nor Garage', value);
    return this;
  }

  includes(vehicle: Vehicle): boolean {
    return this._vehicles.some(vehicle.equals);
  }

  find(predicate: ArrayPredicate<Vehicle>): Vehicle | undefined {
    return this._vehicles.find(predicate);
  }

  map<T>(callback: ArrayCallback<Vehicle, T>): T[] {
    return this._vehicles.map(callback);
  }

  remove(vehicle: Vehicle): VehicleCollection {
    return new VehicleCollection(this._vehicles.filter((v) => v !== vehicle));
  }

  replace(vehicleToReplace: Vehicle, newVehicle: Vehicle): VehicleCollection {
    return new VehicleCollection(this._vehicles.map((v) => (v === vehicleToReplace ? newVehicle : v)));
  }

  reverse() {
    return this._vehicles.reverse();
  }

  repeaterComponents(Component: typeof GarageItem): RepeaterFunctionInvoker<GarageItemParams> {
    return this._vehicles.map((vehicle) => (template) => {
      const props = { template, vehicle, key: vehicle.key };
      return (<Component {...props} />) as TemplateResult;
    });
  }
}

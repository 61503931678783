import requestConfig from 'Models/uiConfig/requestConfig.js';
import { convertibleFromObject } from 'Modules/converter/index.js';
import { facetsToConvertible, makeGetOrDefault } from './common/convert.js';
import { GET, getErrorSearchResponse } from './common/httpClient.js';

const { requestDefaults } = requestConfig;

export default async function facets(request) {
  const getOrDefault = makeGetOrDefault(request, requestDefaults);
  const extra = { ...requestDefaults.extra, ...request.extra };

  try {
    return await GET('facets.json', {
      facet: facetsToConvertible(request),
      extra: await convertibleFromObject(extra),
      referrer: getOrDefault('referrer'),
    });
  } catch (err) {
    console.warn(err || 'Facets request failed');
    const message = await getErrorSearchResponse(err);
    return message;
  }
}

import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggle } from 'Core/actions/request.js';
import {
  createFacetSelector,
  searchRequestSelectionSelector,
  searchQuerySelector,
  totalHitsSelector,
} from 'Core/selectors/search.js';
import { keys, getItem, setItem } from 'Utils/userStorage.js';
import { createFitmentSearchResponseAllFacetsSelector } from 'Core/selectors/fitmentSearch/index.js';

import type { TemplateFunction } from 'Components/types.ts';
import type FacetData from 'Models/facet.ts';

export type Params = {
  isToggled: boolean | null;
  toggleFacet: () => void;
};

export type Props = {
  template: TemplateFunction<Params>;
  toggledFacet: { field: string; term: string };
  visibleIfFields?: Array<string>;
  initToggled?: boolean;
  isInverted?: boolean;
  saveLastState?: boolean;
};

const FacetToggle: FC<Props> = ({
  template,
  toggledFacet,
  visibleIfFields,
  initToggled,
  isInverted,
  saveLastState,
}) => {
  const dispatch = useDispatch();

  const facetToggleField = keys.facetToggleField(toggledFacet.field);
  const [isToggled, setIsToggled] = useState(() =>
    saveLastState && JSON.parse(getItem(facetToggleField)) ? true : null,
  );

  const isVisible = useVisible(visibleIfFields ?? [], toggledFacet);

  const isToggledOnServer = useSelector(searchRequestSelectionSelector).some(
    (el: { field: string; term: string }) => el.field === toggledFacet.field && el.term === toggledFacet.term,
  );

  useEffect(
    function hideWhenFieldsAreNotSelected() {
      window.document.body.classList.toggle('cm_hide-facet-toggle', !isVisible);
    },
    [isVisible],
  );

  useEffect(() => {
    if (isVisible && isToggledOnServer && typeof isToggled !== 'boolean') {
      setIsToggled(true);

      if (saveLastState) {
        setItem(facetToggleField, JSON.stringify(true));
      }
      return;
    }

    if (isVisible && !isToggledOnServer && (isToggled || (typeof isToggled !== 'boolean' && initToggled))) {
      dispatch(toggle(toggledFacet));
    }
  }, [
    dispatch,
    isVisible,
    initToggled,
    isToggled,
    isToggledOnServer,
    toggledFacet,
    saveLastState,
    facetToggleField,
  ]);

  const toggleFacet = () => {
    if (saveLastState) {
      setItem(facetToggleField, JSON.stringify(!isToggled));
    }

    setIsToggled(!isToggled);
    dispatch(toggle(toggledFacet));
  };

  return template.call({
    isToggled: isInverted ? !isToggled : isToggled,
    toggleFacet,
  });
};

export default FacetToggle;

function useVisible(visibleIfFields: Array<string>, toggledFacet: { field: string; term: string }): boolean {
  const facetsSelector = useMemo(() => createFitmentSearchResponseAllFacetsSelector(), []);
  const isAllFacetsHaveSelection = useSelector((state) => {
    const facets = facetsSelector(state);
    return visibleIfFields.every((field) => facets.get(field)?.selection.length);
  });

  const responseQuery = useSelector(searchQuerySelector);
  const hideBySearchQuery = responseQuery && toggledFacet.field === 'Universal';
  const toggledResponseFacet = useSelector(createFacetSelector(toggledFacet.field)) as FacetData;
  const fitmentResponseFacet = useSelector(createFacetSelector('Fitment')) as FacetData;
  const isUniversalFitSelected = fitmentResponseFacet?.selection.some((v) => v.term === 'Universal-Fit');
  const responseTotalHits = useSelector(totalHitsSelector);

  const isFullyToggledFacetSearchResults =
    toggledResponseFacet?.values.find((v) => v.term.toString() === toggledFacet.term)?.hitCount ===
      responseTotalHits && !isUniversalFitSelected;

  return !hideBySearchQuery && !isFullyToggledFacetSearchResults && isAllFacetsHaveSelection;
}

import { createAction } from '@reduxjs/toolkit';

import { isolatedDialog, isolatedHeader } from 'Addons/fitmentSearch/isolatedKeys.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { clearVehicleDialogRequest } from './request.ts';

import type { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';
import type { Vehicle, VehicleCollection } from 'Models/index.ts';
import { VehicleExtra } from 'Models/vehicle.ts';

export const vehicleDiscardingRequested = createAction(
  'VEHICLE_DISCARDING_REQUESTED',
  ({
    isInDialog,
    globalVehicleDiscardEnabled,
    mayDiscardValue,
  }: { isInDialog?: boolean; globalVehicleDiscardEnabled?: boolean; mayDiscardValue?: false } = {}) => ({
    payload: undefined,
    meta: {
      extra: { vehicleJustDiscarded: true },
      sendRequest: requestConfig.hasSearchResults,
      allowSearchSameAsUri: true,
      isInDialog,
      stayOnPage: true,
      mayDiscardValue: mayDiscardValue ?? true,
      globalVehicleDiscardEnabled,
    },
  }),
);

export const vehicleExtraFieldsDiscardingRequested = createAction(
  'VEHICLE_EXTRA_FIELDS_DISCARDING_REQUESTED',
  (isolatedKey: IsolatedKey = null, fitmentBaseFields: string[], fitmentFields: string[]) => ({
    payload: undefined,
    meta: {
      allowSearchSameAsUri: true,
      extra: { vehicleJustSelected: true },
      isolatedKey,
      sendRequest: requestConfig.hasSearchResults,
      stayOnPage: true,
      fitmentBaseFields,
      fitmentFields,
    },
  }),
);

export function isolatedDiscardVehicle(isolatedKey: IsolatedKey, globalVehicleDiscardEnabled?: boolean) {
  return globalVehicleDiscardEnabled
    ? vehicleDiscardingRequested({ isInDialog: isolatedKey === isolatedDialog, globalVehicleDiscardEnabled })
    : isolatedKey === isolatedDialog
      ? clearVehicleDialogRequest()
      : vehicleDiscardingRequested();
}

export const ignoreInexactFields = createAction('IGNORE_INEXACT_FIELDS');

export const vehicleSelected = createAction(
  'VEHICLE_SELECTED',
  (
    vehicle: Vehicle,
    meta: {
      doNotRedirectOnVehicleSelect?: boolean;
      isSpecifyDialogOpened?: boolean;
      isVehicleSelectionForced?: boolean;
      isolatedKey?: IsolatedKey;
      isVehicleAutoSelected?: boolean;
      onInit?: boolean;
      isSelectedFromGarage?: boolean;
      isSelectedFromVerifyFitment?: boolean;
      redirectUrl?: string;
      sendIsolatedRequest?: boolean;
      vehicleWidgetName?: string;
    } = { isolatedKey: null },
  ) => ({
    payload: vehicle,
    meta: {
      allowSearchSameAsUri: true,
      stayOnPage: true,
      mayDiscardValue: true,
      extra: { vehicleJustSelected: true },
      sendRequest:
        requestConfig.hasSearchResults ||
        (meta.isolatedKey === isolatedDialog &&
          fitmentSearchConfig.isOnCategorySelectionPage &&
          fitmentSearchConfig.isCategorySelectionPageRedirectEnabled),
      sendIsolatedRequest: meta.sendIsolatedRequest,
      doNotRedirectOnVehicleSelect: meta.doNotRedirectOnVehicleSelect,
      isSelectedFromDialog: meta.isolatedKey === isolatedDialog,
      isSelectedFromGarage: meta.isSelectedFromGarage,
      isSelectedFromHeader: meta.isolatedKey === isolatedHeader,
      isSelectedFromSearch: meta.isolatedKey === null,
      isSelectedFromVerifyFitment: meta.isSelectedFromVerifyFitment,
      isSpecifyDialogOpened: meta.isSpecifyDialogOpened,
      isVehicleAutoSelected: meta.isVehicleAutoSelected,
      isVehicleSelectionForced: meta.isVehicleSelectionForced,
      onInit: meta.onInit,
      redirectUrl: meta.redirectUrl,
      vehicleWidgetName: meta.vehicleWidgetName,
    },
  }),
);

export const forceVehicleSelection = createAction('FORCE_VEHICLE_SELECTION', () => ({
  payload: null,
}));

export const forceVehicleSelectionLater = createAction('FORCE_VEHICLE_SELECTION_LATER', () => ({
  payload: null,
}));

export const vehicleSpecificationNeeded = createAction(
  'VEHICLE_SPECIFICATION_NEEDED',
  (fieldsToSpecify: string[], isolatedKey: IsolatedKey = null) => ({
    payload: fieldsToSpecify,
    meta: { isolatedKey },
  }),
);

export const setGarageRequested = createAction(
  'SET_GARAGE_REQUESTED',
  (payload: { vehicles: VehicleCollection; selectVehicle: boolean }) => ({
    payload,
    meta: {
      allowSearchSameAsUri: true,
      mayDiscardValue: true,
      stayOnPage: true,
    },
  }),
);

export const removalFromGarageRequested = createAction(
  'REMOVAL_FROM_GARAGE_REQUESTED',
  (vehicle: Vehicle, wasSelected: boolean) => ({
    payload: vehicle,
    meta: {
      wasSelected,
      sendRequest: wasSelected && requestConfig.hasSearchResults,
      allowSearchSameAsUri: true,
      stayOnPage: true,
      mayDiscardValue: true,
    },
  }),
);

export const addToGarageRequested = createAction('ADD_TO_GARAGE_REQUESTED', (vehicle: Vehicle) => ({
  payload: vehicle,
  meta: {
    stayOnPage: true,
  },
}));

export const garageCleanupRequested = createAction('GARAGE_CLEANUP_REQUESTED', () => ({
  payload: undefined,
  meta: {
    sendRequest: requestConfig.hasSearchResults,
    allowSearchSameAsUri: true,
    stayOnPage: true,
    mayDiscardValue: true,
  },
}));

export const vehicleColorUpdated = createAction(
  'VEHICLE_COLOR_UPDATED',
  (payload: { vehicle: Vehicle; vehicleExtra: VehicleExtra }) => ({
    payload,
    meta: {
      stayOnPage: true,
    },
  }),
);

import { createAction } from '@reduxjs/toolkit';

import { isolatedDialog, isolatedHeader, isolatedVerifyFitment } from 'Addons/fitmentSearch/isolatedKeys.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { clearVehicleDialogRequest, clearVerifyFitmentRequest } from './request.ts';

import type { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';
import type { Vehicle, VehicleCollection } from 'Models/index.ts';
import { VehicleExtra } from 'Models/vehicle.ts';

export const vehicleDiscardingRequested = createAction(
  'VEHICLE_DISCARDING_REQUESTED',
  (isInDialog?, { mayDiscardValue }: { mayDiscardValue?: false } = {}) => ({
    payload: undefined,
    meta: {
      extra: { vehicleJustDiscarded: true },
      sendRequest: requestConfig.hasSearchResults,
      allowSearchSameAsUri: true,
      isInDialog,
      stayOnPage: true,
      mayDiscardValue: mayDiscardValue ?? true,
    },
  }),
);

export const vehicleExtraFieldsDiscardingRequested = createAction(
  'VEHICLE_EXTRA_FIELDS_DISCARDING_REQUESTED',
  (isolatedKey: IsolatedKey = null, fitmentBaseFields: string[], fitmentFields: string[]) => ({
    payload: undefined,
    meta: {
      allowSearchSameAsUri: true,
      extra: { vehicleJustSelected: true },
      isolatedKey,
      sendRequest: requestConfig.hasSearchResults,
      stayOnPage: true,
      fitmentBaseFields,
      fitmentFields,
    },
  }),
);

export function isolatedDiscardVehicle(isolatedKey: IsolatedKey, globalVehicleDiscardEnabled?: boolean) {
  return globalVehicleDiscardEnabled
    ? vehicleDiscardingRequested(isolatedKey === isolatedDialog)
    : isolatedKey === isolatedDialog
      ? clearVehicleDialogRequest()
      : isolatedKey === isolatedVerifyFitment
        ? clearVerifyFitmentRequest()
        : vehicleDiscardingRequested();
}

export const ignoreInexactFields = createAction('IGNORE_INEXACT_FIELDS');

export const vehicleSelected = createAction(
  'VEHICLE_SELECTED',
  (
    vehicle: Vehicle,
    meta: {
      doNotRedirectOnVehicleSelect?: boolean;
      isSpecifyDialogOpened?: boolean;
      isVehicleSelectionForced?: boolean;
      isolatedKey?: IsolatedKey;
      isVehicleAutoSelected?: boolean;
      onInit?: boolean;
      isSelectionFromGarage?: boolean;
      redirectUrl?: string;
      sendIsolatedRequest?: boolean;
      vehicleWidgetName?: string;
    } = { isolatedKey: null },
  ) => ({
    payload: vehicle,
    meta: {
      allowRedirect:
        ((meta.isolatedKey === isolatedHeader && !fitmentSearchConfig.isOnCategorySelectionPage) ||
          (meta.isolatedKey === isolatedDialog &&
            !meta.isSpecifyDialogOpened &&
            !meta.isVehicleSelectionForced) ||
          meta.isSelectionFromGarage ||
          (!meta.isolatedKey && meta.redirectUrl)) &&
        !meta.doNotRedirectOnVehicleSelect &&
        !meta.onInit &&
        !(
          fitmentSearchConfig.isAutoRedirectDisabled &&
          meta.isVehicleAutoSelected &&
          !(meta.isolatedKey === isolatedDialog && !meta.isSpecifyDialogOpened)
        ),
      allowSearchSameAsUri: true,
      doNotRedirectOnVehicleSelect: meta.doNotRedirectOnVehicleSelect,
      extra: { vehicleJustSelected: true },
      isVehicleAutoSelected: meta.isVehicleAutoSelected,
      sendIsolatedRequest: meta.sendIsolatedRequest,
      sendRequest:
        (meta.isolatedKey === isolatedDialog && !meta.isSpecifyDialogOpened) || meta.isSelectionFromGarage
          ? requestConfig.hasSearchResults ||
            (fitmentSearchConfig.isOnCategorySelectionPage &&
              fitmentSearchConfig.isCategorySelectionPageRedirectEnabled)
          : requestConfig.hasSearchResults,
      stayOnPage: true,
      mayDiscardValue: true,
      redirectUrl: meta.redirectUrl,
      vehicleWidgetName: meta.vehicleWidgetName,
    },
  }),
);

export const forceVehicleSelection = createAction('FORCE_VEHICLE_SELECTION', () => ({
  payload: null,
}));

export const forceVehicleSelectionLater = createAction('FORCE_VEHICLE_SELECTION_LATER', () => ({
  payload: null,
}));

export const vehicleSpecificationNeeded = createAction(
  'VEHICLE_SPECIFICATION_NEEDED',
  (fieldsToSpecify: string[], isolatedKey: IsolatedKey = null) => ({
    payload: fieldsToSpecify,
    meta: { isolatedKey },
  }),
);

export const setGarageRequested = createAction(
  'SET_GARAGE_REQUESTED',
  (payload: { vehicles: VehicleCollection; selectVehicle: boolean }) => ({
    payload,
    meta: {
      allowSearchSameAsUri: true,
      mayDiscardValue: true,
      stayOnPage: true,
    },
  }),
);

export const removalFromGarageRequested = createAction(
  'REMOVAL_FROM_GARAGE_REQUESTED',
  (vehicle: Vehicle, wasSelected: boolean) => ({
    payload: vehicle,
    meta: {
      wasSelected,
      sendRequest: wasSelected && requestConfig.hasSearchResults,
      allowSearchSameAsUri: true,
      stayOnPage: true,
      mayDiscardValue: true,
    },
  }),
);

export const addToGarageRequested = createAction('ADD_TO_GARAGE_REQUESTED', (vehicle: Vehicle) => ({
  payload: vehicle,
  meta: {
    stayOnPage: true,
  },
}));

export const garageCleanupRequested = createAction('GARAGE_CLEANUP_REQUESTED', () => ({
  payload: undefined,
  meta: {
    sendRequest: requestConfig.hasSearchResults,
    allowSearchSameAsUri: true,
    stayOnPage: true,
    mayDiscardValue: true,
  },
}));

export const vehicleColorUpdated = createAction(
  'VEHICLE_COLOR_UPDATED',
  (payload: { vehicle: Vehicle; vehicleExtra: VehicleExtra }) => ({
    payload,
    meta: {
      stayOnPage: true,
    },
  }),
);
